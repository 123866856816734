import React, {useState} from "react";
import Layout from "../components/user_layout";
import password from "../assets/images/password.svg";
import { Link } from "react-router-dom";
import arrowleft from "../assets/images/arrow-left.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/authcontext/auth_context";
import { FaSpinner } from "react-icons/fa";


const schema = yup.object().shape({
  email: yup.string().email().required(),
});

const Forget_password = () => {
  const auth_context = useContext(AuthContext);
  const { dispatch } = auth_context;

  const {
    register,
    setError,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const url = window.location.origin;
  const forgetPasswordApi = `${process.env.REACT_APP_API_URL}/v1/auth/talent-forgot-password`;

  const onSubmitHandler = (data) => {
    data.forgotPasswordUrl = `${url}/set_new_password`
    setIsLoading(true);
    dispatch({ type: "SET_EMAIL", payload: data });
    axios
      .post(forgetPasswordApi, data)

      .then((response) => {
        navigate("/forget_password_otp");
      })
      .catch((error) => {
        setError("apiError", { message: error });
      });
  };

  return (
    <div className="password">
      <Layout>
        <div className="font-poppins">
          <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
            <div className="'mx-auto bg-center">
              <img src={password} alt="" className="object-center" />
            </div>

            <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
              {" "}
              Forgot Password?{" "}
            </h1>
          </div>
          <div className=" mx-auto  w-4/12 pb-4">
            {" "}
            <p className=" text-center subtext ">
              Enter your email address and we’ll send you a code to reset your
              password.
            </p>{" "}
          </div>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className=" mx-auto  w-4/12">
              <label htmlFor="">Email Address</label> <br />
              <input
                type="email"
                name="email"
                required
                className="form-control w-full mt-2 font-poppins"
                placeholder="name@email.com"
                {...register("email", { required: true })}
              />
              <p className="text-red-600 font-bold ">
                {errors.apiError &&
                  "Invalid email, please insert your valid email"}
              </p>
            </div>
            <div className="mx-auto w-4/12">
            <button
                id="my-button"
                onClick={onSubmitHandler}
                className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex  place-content-center"
              >
                {isLoading && (
                  <FaSpinner
                    isLoading={isLoading}
                    className={`mt-1 mr-2 spinner-1  `}
                  />
                )}
                {isLoading ? <span>Sending </span> : <span>Send link to email</span>}
              </button>

            </div>
          </form>

          <Link
            to="/login"
            className=" flex mx-auto place-content-center mt-4 subtext lg:text-[12px]"
          >
            <img src={arrowleft} alt="" /> Back to log in
          </Link>
        </div>
      </Layout>
    </div>
  );
};

export default Forget_password;
