import React from "react";

const Create_test = () => {
  return (
    <div className="my-32 grid place-items-center">
      <h1 className="highlight text-xl py-4 px-8 lg:w-6/12">Create Test</h1>
      <div className="border-r border-l border-b rounded-b-2xl px-8 lg:w-6/12">
        <div className="my-6">
          <label htmlFor="">Test type</label> <br />
          <select name="" id="" className="w-full mt-1">
            <option value="" disabled>
              Select Test Type
            </option>
            <option value="">Basic Fit Test</option>
            <option value="">Written Test</option>
            <option value="">Speech Test</option>
            <option value="">Role Specific Test</option>
          </select>
        </div>
        <button className="btn w-full my-6">Proceed</button>
      </div>
    </div>
  );
};

export default Create_test;
