import React from "react";
import { FiClock } from "react-icons/fi";
import { IoCalendarOutline } from "react-icons/io5";
import moment from "moment/moment";
import CounterTwo from "./counterTwo";

const Banner = (props) => {
  var duration;

  if (
    ["/tests/speech-test", "/speech_test_guidelines", "/test_mic"].includes(
      window.location.pathname
    )
  ) {
    duration = 3;
  } else if (
    [
      "/written_test",
      "/role_specific_test",
      "/written_test_guidelines",
      "/role_specific_test_guidelines",
    ].includes(window.location.pathname)
  ) {
    duration = 15;
  } else {
    duration = 10;
  }

  return (
    <div className="flex place-content-between bg-[#EFECF9] py-4 px-20 banner font-poppins sticky top-0">
      <div className="flex text-lg lg:text-2xl">

        <p className="text-[#4E4A5A]">{props.title}</p>
      </div>
      <div className="flex lg:text-xl ml-20">
        <FiClock className="self-center clc text-[#4214C8] mr-2" size={28} />
        <p className="text-[#4E4A5A] duration">Duration: {duration} Mins</p>
        <span className="counter">
          <CounterTwo />
        </span>
      </div>
      <div className="flex lg:text-xl ">
        <IoCalendarOutline
          className="self-center text-[#4214C8] mr-2"
          size={28}
        />
        <p className="text-[#4E4A5A] pt-1">
          Date: {moment().format("Do MMMM YYYY")}
        </p>
      </div>
    </div>
  );
};

export default Banner;
