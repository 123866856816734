import * as React from "react";
import PropTypes from "prop-types";
import Navbar from "./navbar";
import Footer from "./footer";
import ViewportError from "./viewportError";
import Logout from "./logout";
import Sidebar from "./sidebar";

let pathname;
if (typeof window !== "undefined") {
  pathname = window.location.pathname;
}

const Layout = ({ children }) => {
  return (
    <>
      <ViewportError />
          <div className="grid ">
            <div className="grid grid-cols-2 fixed bg-white w-full">
              <Navbar />
              <div className="ml-auto pr-20 lg:pr-40"><Logout /></div>
            </div>  
            <div className=" flex mt-24  ">
              <div className="fixed"><Sidebar/></div> 
              <main className=" w-8/12 ml-24 lg:w-full lg:ml-72">{children}</main>
            </div>
            <Footer />
     
      </div>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
