import React from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";

const Privacy_Policy = () => {
  return (
    <div className="hiddenPages tc">
      <Layout>
        <div className="test_guidelines tc font-poppins">
          <Banner title="Hirefoster by Zedi Privacy Policy" />
          <div className="px-8 lg:px-32 mx-20 pb-28 pt-16  bg-[#F5F3FB] my-10">
            <p className="texts leading-10  text-[18px] pt-4 mb-6">
              This Privacy Policy (&quot;Policy&quot;) explains how Hirefoster
              by Zedi (&quot;Company,&quot; &quot;we,&quot; &quot;us,&quot; or
              &quot;our&quot;) collects, uses, and protects the personal
              information of users (&quot;User&quot; or &quot;you&quot;) when
              you access or take the talent test. We are committed to respecting
              your privacy and protecting your personal information. By using
              the Hirefoster by Zedi Website, you consent to the practices
              described in this Policy.
            </p>
            <p className="texts leading-10 font-semibold text-[18px] mb-6">
              1. Information We Collect
            </p>
            <p className="texts leading-10  text-[18px]">
              <strong>1.1 </strong> Personal Information: When you create an
              account or participate in the talent test, we may collect personal
              information such as your name, email address and phone number. We
              may also collect information such as your <strong>gender</strong>,{" "}
              <strong>sex</strong>, and <strong>age</strong>.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>1.2 </strong> Usage Information: such as website usage,
              interactions with our platform.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>1.3 </strong> Employment Information: such resumes,
              portfolios and social media links.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>1.4 </strong>Cookies and Similar Technologies: We may use
              cookies and similar tracking technologies to enhance your
              experience and collect information about your usage patterns.
            </p>

            <p className="texts leading-10 text-[18px] mt-4"></p>
            <p className="texts leading-10 font-semibold text-[18px] my-6">
              2. Use of Information
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              We use the collected information to:
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.1 </strong> Provide and improve the Hirefoster by Zedi
              website, facilitate communication between users process payments,
              and personalize your experience.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.2 </strong> Match job seekers with job opportunities.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.3 </strong> Carry out research and statistical analysis
              to monitor how users’ use our website, products and services on an
              anonymous or personal basis.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.4 </strong> Send important notifications, updates, or
              job availability.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.5 </strong> Improve our services.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              3. Information Sharing
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              We may share your information with:
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>3.1 </strong> Prospective employers.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>3.2 </strong> Third-party service providers (e.g, for
              background checks). These service providers are bound by
              confidentiality obligations and are prohibited from using your
              personal information for any other purpose.
            </p>
            <p className="texts leading-10 text-[18px] my-6">
              <strong>3.3 </strong> Legal authorities, if required by law,
              regulation, or legal process, or if we believe that disclosure is
              necessary to protect our rights, property, or safety, or the
              rights, property, or safety of other
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              4. Data Security
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>4.1 </strong> Maintaining the security of Your Personal
              Information is important to us. Reasonable security safeguards
              have been implemented to protect Your Personal Information.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>4.2 </strong> We implement reasonable security measures to
              protect your personal information from unauthorized access,
              disclosure, alteration, or destruction.
            </p>

            <p className="texts leading-10  text-[18px] my-6">
              <strong>4.3 </strong> However, no method of transmission over the
              internet or electronic storage is 100% secure. Therefore, while we
              strive to protect your personal information, we cannot guarantee
              its absolute security.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              5. Data Retention
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>5.1 </strong> We retain your personal information for as
              long as necessary to fulfill the purposes outlined in this Policy,
              unless a longer retention period is required or permitted by law.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              6. Your Rights as a User
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.1 </strong> Erasing your information: You have the right
              to request that we erase your personal information if it is no
              longer valid or necessary for the purposes for which it was
              collected or if it is incomplete or inaccurate.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.2 </strong> Receiving your information: You have the
              right to receive your personal information in a commonly used and
              machine-readable format and the right to transmit these data to
              another Data Controller when the processing is based on (explicit)
              consent or when the processing is necessary for the performance of
              a contract.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.3 </strong> You can review the information you provide
              to us and make any desired changes to the information you have
              provided. Please be aware that even after your request for a
              change is processed, we may, for a time, retain residual
              information about you in our backup and/or archival copies of our
              database.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.4 </strong> Direct Marketing: You have the right to
              choose whether you receive marketing material or not. By accepting
              using our products and services, you are also agreeing to us
              sending you marketing material on related services, products and
              offerings provided by us from time to time. If you change your
              mind at any time, you are always able to opt-out.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.5 </strong>Your personal information shall be subject to
              the Nigerian Data Protection Regulation 2019 (as may be amended
              from time to time) and any other applicable Data Protection
              Regulation.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              7. Your Obligations
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              As a talent, there are certain obligations expected from You. Some
              of these obligations are imposed by applicable law and
              regulations, and others have become commonplace for us:
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>7.1 </strong> You must always abide by the terms of this
              notice and any agreement or consent relating to our services that
              you have subscribed to. this includes respecting all intellectual
              property rights that may belong to third parties (such as
              trademarks or photographs).
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>7.2 </strong> You must not provide us with information
              that you believe might be false or misleading.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>7.3 </strong> You must keep your username and password
              confidential and not share it with others.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>7.4 </strong> Promptly notify us at the contact details
              indicated below, where you wish to exercise any of your rights
              with respect to your personal information.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              8. Third-Party Links
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>8.1 </strong> The Hirefoster by Zedi Website may contain
              links to third-party websites or services. We are not responsible
              for the privacy practices or content of those third parties. We
              encourage you to review the privacy policies of those third
              parties before providing any personal information.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              9. Children&#39;s Privacy
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>9.1 </strong>The Hirefoster by Zedi Website is not
              intended for use by individuals under the age of 18. We do not
              knowingly collect personal information from children. If we become
              aware that we have inadvertently collected personal information
              from a child, we will take steps to delete such information as
              soon as possible.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              10. Reporting Incidents
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>10.1 </strong> In the event of a privacy breach, we shall
              report such breach to the relevant authority and if necessary,
              affected individuals of personal data breach (where the personal
              data breach will likely result in high risks to the freedoms and
              rights of the data subject) within reasonable time of being aware
              of the breach.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>10.2 </strong> We shall also take steps to investigate and
              recover personal data and will also ensure that controls are
              enhanced to prevent a re-occurrence of the breach.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>10.3 </strong>You have a right to lodge a complaint before
              an appropriate authority for any breach of Your rights.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              11. Changes to this Privacy Policy
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>11.1 </strong> We reserve the right to update or modify
              this Policy at any time. Any changes will be effective when we
              post the revised Policy on the Hirefoster by Zedi Website. Your
              continued use of the Website following the posting of any changes
              constitutes your acceptance of those changes.
            </p>
            <p className="texts leading-10 font-semibold text-[18px] my-6">
              12. Contact Us
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>12.1 </strong> If you have any questions or concerns about
              this Privacy Policy or our data practices, please contact us at
              hirefoster@zedi.africa. By using the Hirefoster by Zedi Website,
              you acknowledge that you have read, understood, and agree to the
              practices described in this Privacy Policy.
            </p>
          </div>
          <div className="grid mx-20">
            <div>
              <Link to="/">
                <button className="btn float-right w-full lg:w-96 flex justify-center">
                  <span className="pt-1 self-center">Go Back</span>
                  <HiArrowRight className="self-center ml-2" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Privacy_Policy;
