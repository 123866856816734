import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import Admin_header from "../../components/adminheader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Score_writtenTest = () => {
  const url = window.location.origin;
  const params = useParams();
  const { applicationId } = params;
  const [questions, setQuestions] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [submission, setSubmission] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-submission/${applicationId}?isGraded=false`;

      await axios
        .get(api, { headers })
        .then((res) => {
          const resQuestions = res.data.data.docs;
          setQuestions(resQuestions);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    })();
  }, []);

  const [token, setToken] = useState(() => localStorage.adminToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/applications/${applicationId}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const data = res.data.data;
          setTalentData(data);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-scores/${applicationId}`;
      await axios
        .patch(
          api,
          {
            scores: submission,
            writtenTestUrl: `${url}/written_test_guidelines`,
            roleSpecificTestUrl: `${url}/role_specific_test_guidelines`,
          },
          { headers }
        )
        .then((res) => {
          if (submission.length >= 5) {
            const totalSum =
              Number(submission[0].score) +
              Number(submission[1].score) +
              Number(submission[2].score) +
              Number(submission[3].score) +
              Number(submission[4].score);
            localStorage.setItem("totalScore", totalSum);
          }

          // Change color of gradeBox
          const totalScore = localStorage.getItem("totalScore");
          var total = totalScore * 25;
          if (total >= 60) {
            document.getElementById("gradeBox").style.backgroundColor =
              "#EFF9EF";
            document.getElementById("gradeText").style.color = "#2BA96F";
          } else {
            document.getElementById("gradeBox").style.backgroundColor =
              "#FFEAEA";
            document.getElementById("gradeText").style.color = "#AB0B0B";
          }

          // Change score
          var str = document.getElementById("gradeText").innerHTML;
          var res = str.replace(/25/g, totalScore + "/25");
          document.getElementById("gradeText").innerHTML = res;

          // Disable button
          document.getElementById("submitBtn").disabled = true;
          if ((document.getElementById("submitBtn").disabled = true)) {
            document.getElementById("submitBtn").style.backgroundColor =
              "#c2c2d6";
          }

          toast.success("Written test has been marked succesfully!");
        });
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    for (const idx of submission) {
      if (idx.submissionId === id) {
        setSubmission(
          submission.map((item) =>
            item.submissionId === id ? { ...item, score: Number(value) } : item
          )
        );
        return;
      }
    }
    setSubmission([...submission, { score: Number(value), submissionId: id }]);
  };

  return (
    <div className="allAuth none">
      <Layout>
        <div className="px-14">
          <Admin_header title={"Written Test Result - " + talentData.role} />
          <ToastContainer autoClose={7000} />
          <div className="grid gap-y-8">
            <div
              className="border rounded-2xl p-8 grid grid-cols-2 mt-8 text-[#797488]"
              id="gradeBox"
            >
              <div>
                <h3 className="font-bold text-2xl text-black">
                  {" "}
                  {talentData?.talent?.firstName} {talentData?.talent?.lastName}
                </h3>
                <p> {talentData?.talent?.email}</p>
              </div>

              <div>
                <span>
                  <div className="text-[32px] -mb-2 text-center" id="gradeText">
                    25
                  </div>
                  <p className="text-center">points</p>
                </span>
              </div>
            </div>

            {questions?.map((question, index) => (
              <form className="grid gap-y-8" key={question?.id}>
                <div className="grid gap-y-4 pl-8">
                  <div className="flex text-xl -ml-8">
                    <div className="pr-4">{index + 1} </div>
                    <p>{question?.question} </p>
                  </div>

                  <p>Answer:</p>
                  <p>{question?.answer}</p>

                  <div className="grid grid-cols-7 bg-gray-100 py-3 px-12 rounded mb-8">
                    <p>Score:</p>
                    <div className="flex">
                      <input
                        type="radio"
                        name={question?.id}
                        id=""
                        className="px-12"
                        value={1}
                        onChange={(e) => handleChange(e, question?.id)}
                      />
                      <p className="ml-2">1 points</p>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        name={question?.id}
                        id=""
                        className="px-12"
                        value={2}
                        onChange={(e) => handleChange(e, question?.id)}
                      />
                      <p className="ml-2">2 points</p>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        name={question?.id}
                        id=""
                        className="px-12"
                        value={3}
                        onChange={(e) => handleChange(e, question?.id)}
                      />
                      <p className="ml-2">3 points</p>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        name={question?.id}
                        id=""
                        className="px-12"
                        value={4}
                        onChange={(e) => handleChange(e, question?.id)}
                      />
                      <p className="ml-2">4 points</p>
                    </div>
                    <div className="flex">
                      <input
                        type="radio"
                        name={question?.id}
                        id=""
                        className="px-12"
                        value={5}
                        onChange={(e) => handleChange(e, question?.id)}
                      />
                      <p className="ml-2">5 points</p>
                    </div>
                  </div>
                </div>
              </form>
            ))}
            <button
              className="btn my-20 w-full"
              type="button"
              onClick={handleSubmit}
              id="submitBtn"
            >
              Mark Test
            </button>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Score_writtenTest;
