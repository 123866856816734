import React from "react";
import { Link } from "react-router-dom";
import facebook from "../assets/icons/facebook.svg";
import twitter from "../assets/icons/twitter.svg";
import instagram from "../assets/icons/instagram.svg";
import linkedin from "../assets/icons/linkedin.svg";

const Footer = () => {
  return (
    <footer className="mt-32 mb-5">
      <div className="grid lg:flex">
        <div className="pb-3 font-poppins">
          © Copyright 2022 - Zedi Africa Limited, All rights reserved.
        </div>

        <div className="flex gap-x-1 md:gap-x-3 -mt-6 lg:mt-0 lg:mx-auto order-2 lg:-order-none ml-auto font-poppins">
          <Link to="/about">About</Link>
          <Link to="/services">Services</Link>
          <Link to="/freelance">Freelance</Link>
        </div>

        <div className="flex gap-x-1 md:gap-x-3">
          <a
            href="https://m.facebook.com/Zedi-Africa-105144468785510/"
            target="_blank"
            className=""
          >
            <img src={facebook} alt="facebook" />
          </a>
          <a href="https://twitter.com/zediafrica?s=11" target="_blank">
            <img src={twitter} alt="twitter" />
          </a>
          <a
            href="https://instagram.com/zediafrica?utm_medium=copy_link"
            target="_blank"
            className=""
          >
            <img src={instagram} alt="instagram" />
          </a>
          <a href="https://www.linkedin.com/company/zedi-africa/" target="_blank" className="">
            <img src={linkedin} alt="linkedin" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
