import React, { useEffect, useState } from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { HiArrowRight } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CURRENT_TEST_TOKEN } from "../utils/constants";

const Written_Test = () => {
  const [questions, setQuestions] = useState([]);
  const [get, setGet] = useState([]);
  const url = window.location.origin;
  const navigate = useNavigate();

  const headers = {
    Authorization: "Bearer " + localStorage.getItem("userToken"),
  };

  useEffect(() => {
    (async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-talent?token=${localStorage.getItem(CURRENT_TEST_TOKEN)}`;
      axios
        .get(api, { headers })
        .then(
          ({
            data: {
              data: { questions },
            },
          }) => {
            setGet(questions);
          }
        )
        .catch(({ response }) => {
          const toastMessage =
            response.status === 401
              ? `Unauthorised. Please login again to continue`
              : response.data.message;
          toast.error(toastMessage, { toastId: 2 });
          setTimeout(() => {
            navigate("/login");
          }, 6000);
        });
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-submission`;
      await axios
        .post(
          api,
          {
            submission: questions,
            roleSpecificTestUrl: `${url}/role_specific_test_guidelines`,
            viewSubmissionUrl: `${url}/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result`,
          },
          { headers }
        )
        .then((res) => {
          navigate("/status/success");
        });
    } catch (error) {
      toast.error(error.response.data.message, { toastId: 2 });
      setTimeout(() => {
        navigate("/login");
      }, 6000);
    }
  };

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    for (const idx of questions) {
      if (idx.questionId === id) {
        setQuestions(
          questions.map((item) =>
            item.questionId === id ? { ...item, answer: value } : item
          )
        );
        return;
      }
    }
    setQuestions([...questions, { answer: value, questionId: id }]);
  };

  document.addEventListener("visibilitychange", (e) => {
    if (document.visibilityState === "visible") {
      let submitBtn = document.getElementById("submitBtn");
      submitBtn.click();
    } else {
    }
  });

  return (
    <div className="hiddenPages">
      <Layout>
        <div className="test font-poppins">
          <Banner title="Written Test" />
          <form
            action=""
            className="w-8/12 mx-auto py-14"
            onSubmit={handleSubmit}
          >
            {get.map((x, index) => (
              <div className="py-4" key={x.id}>
                <p className="font-medium text-xl leading-8 pb-4">
                  {index + 1 + ". "} {x.question}
                </p>

                <textarea
                  name="answer"
                  id=""
                  placeholder="Answer"
                  className="w-full px-4 pt-2 rounded border border-[#CCCCCC]"
                  onChange={(e) => handleChange(e, x.id)}
                ></textarea>
              </div>
            ))}

            {get.length !== 0 ? (
              <button
                id="submitBtn"
                className="btn float-right w-full flex justify-center mt-10"
              >
                <span>Submit</span>
                <HiArrowRight className="self-center ml-2" />
              </button>
            ) : (
              ""
            )}
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Written_Test;
