export const LEVELS = [
  { label: "Intern", title: "Intern" },
  { label: "Beginner", title: "Beginner" },
  { label: "Intermediate", title: "Intermediate" },
  { label: "Expert", title: "Expert" },
];

export const ROLES = [
  { label: "Strategist", title: "Strategist" },
  { label: "Social/Digital Marketer", title: "Social/Digital Marketer" },
  { label: "Media Buyer", title: "Media Buyer" },
  { label: "Content Manager", title: "Content Manager" },
  { label: "Growth Marketer", title: "Growth Marketer" },
  { label: "Graphic/Product Designer", title: "Graphic/Product Designer" },
  { label: "Product Marketer", title: "Product Marketer" },
  { label: "Sales Executive", title: "Sales Executive" },
  { label: "Community Manager (Web2)", title: "Community Manager (Web2)" },
  { label: "Community Manager (Web3)", title: "Community Manager (Web3)" },
  {
    label: "Technical Content Writer(SEO)",
    title: "Technical Content Writer(SEO)",
  },
  {
    label: "Technical Content Writer(UX)",
    title: "Technical Content Writer(UX)",
  },
  { label: "B2BSales Content Writer", title: "B2BSales Content Writer" },
  {
    label: "Content Creator (Social Video/Skits)",
    title: "Content Creator (Social Video/Skits)",
  },
  {
    label: "PR/Communications Specialist",
    title: "PR/Communications Specialist",
  },
  { label: "PR/Tech Journalist", title: "PR/Tech Journalist" },
  { label: "Copywriter", title: "Copywriter" },
  { label: "Customer Success Executive", title: "Customer Success Executive" },
  {
    label: "Telemarketer/Virtual Assistant",
    title: "Telemarketer/Virtual Assistant",
  },
  {
    label: "B2BSales/Tech Sales Executive",
    title: "B2BSales/Tech Sales Executive",
  },
  {
    label: "Business Development Manager",
    title: "Business Development Manager",
  },
  { label: "Multimedia Executives", title: "Multimedia Executives" },
  { label: "Animator", title: "Animator" },
  { label: "Project Manager", title: "Project Manager" },
  { label: "Operations Manager", title: "Operations Manager" },
  { label: "Clients Service Executive", title: "Clients Service Executive" },
];

export const TESTS = [
  { label: "All tests", title: "All tests" },
  { label: "Passed tests", title: "Passed tests" },
  { label: "Failed tests", title: "Failed tests" },
];

export const TEST_ROLES = [
  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Strategist",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Social/Digital Marketer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Media Buyer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Content Manager",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Growth Marketer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Graphic/Product Designer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Product Marketer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Sales Executive",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Community Manager (Web2)",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Technical Content Writer(SEO)",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Technical Content Writer(UX)",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "B2BSales Content Writer",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Content Creator (Social Video/Skits)",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "PR/Communications Specialist",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "PR/Tech Journalist",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Copywriter",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Customer Success Executive",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Telemarketer/Virtual Assistant",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "B2BSales/Tech Sales Executive",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Business Development Manager",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Multimedia Executive",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Animator",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Project Manager",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Operations Manager",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
    title: "Clients Service Executive",
  },
];

export const PATH = [
  {
    path: "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/talent-dashboard",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/create_test",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/basicfit_add_questions",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/speech_add_question",
  },

  {
    path: "/zedi/operations/mtaas-talent/admin/rolespecific_add_question",
  },
];
