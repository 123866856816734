import React from "react";
import Layout from "../../components/user_layout";
import success from "../../assets/icons/success.svg";
import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="confirmation font-poppins">
      <Layout>
        <div className="mx-auto text-center w-screen content-center px-8 pt-14 md:px-0 md:w-9/12">
          <img src={success} alt="" className="mx-auto w-3/12" />
          <p className="font-bold text-[32px] pt-6 pb-3">Test Submitted!</p>
          <p className="subtext text-[16px]">
            Look out for your result in your email.
          </p>
          <p className="subtext text-[16px]">
            For written test and role-specific test, you will
          </p>
          <p className="subtext text-[16px]">
            get a mail after your submission has been reviewed.
          </p>
          <Link to={"/"}>
            {" "}
            <button className="btn my-10 !px-16">Go Home</button>
          </Link>
        </div>
      </Layout>
    </div>
  );
};

export default Success;
