import React, { useState } from "react";
import {  NavLink } from "react-router-dom";
import { FiGrid, FiClipboard, FiUsers, FiMenu } from "react-icons/fi";

const Sidebar = () => {
  

  const menuItem = [
    {
      path: "/zedi/operations/mtaas-talent/admin/dashboard",
      name: "Dashboard",
      icon: <FiGrid />,
    },

    {
      path: "/zedi/operations/mtaas-talent/admin/test-dashboard",
      name: "Test",
      icon: <FiClipboard />,
    },

    {
      path: "/zedi/operations/mtaas-talent/admin/talent-dashboard",
      name: "Talents",
      icon: <FiUsers />,
    },
  ];

  return (
    <div className="h-screen ">
        <div className="sidebar ">
            <div className="ml-6 mt-6 lg:top-section">
                <FiMenu  className='bar'/>

            </div>

            {
                menuItem.map((item, index) => (
                    <NavLink to={item.path} key={index} className="link lg:link " activeclassname={` "active"`}>
                        <div className="icon">{item.icon}</div>
                        <div className="link-text invisible text-xs lg:link-text  lg:visible  lg:text-base pt-1 lg:pt-0">{item.name}</div>

                    </NavLink>
                ))
            }


            <div className='px-6 text-xs lg:text-sm text-gray-400 absolute bottom-0 !mb-32'>© 2022 - Zedi Africa Limited, All rights reserved.</div>
        </div>


      </div>
  );
};

export default Sidebar;
