import React, { useState } from "react";
import Layout from "../components/user_layout";
import Failure from "../assets/images/failure.svg";
import { useNavigate } from "react-router-dom";

const Speech_failure = () => {
  const [retakeToken, setRetakeToken] = useState(
    () => localStorage.retakeToken
  );
  const [retakeTest, setRetakeTest] = useState(
    () => localStorage.canRetakeTest
  );
  const navigate = useNavigate();

  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
          <div className="'mx-auto bg-center">
            <img src={Failure} alt="" className="object-center" />
          </div>
          <h1 className="text-2xl lg:text-[32px] header font-semibold mt-10">
            {" "}
            Sorry, that did not sound right
          </h1>

          <p className="text-center texts mt-4 text-2xl font-medium">
            You got below the required score to go to the next test, you have
            one more try to make it right.
          </p>

          <button
            onClick={() => navigate("/speech_test_guidelines")}
            className=" flex btn mx-auto w-2/12 place-content-center mt-10 text-20[px]"
          >
            Retake test
          </button>
        </div>
      </Layout>
    </div>
  );
};

export default Speech_failure;
