import React, { useContext, useState } from "react";
import Layout from "../components/user_layout";
import email from "../assets/images/email.svg";
import { Link } from "react-router-dom";
import arrowleft from "../assets/images/arrow-left.svg";
import AuthContext from "../context/authcontext/auth_context";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import eye from "../assets/images/eye.svg";
import hidden from "../assets/images/eye-off.svg";
import { FaSpinner } from "react-icons/fa";

const Forget_password_otp = () => {
  const [otpState, setOtpState] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  const auth_context = useContext(AuthContext);
  const { dispatch, userEmail } = auth_context;

  const navigate = useNavigate();
  const forgetPasswordURL = `${process.env.REACT_APP_API_URL}/v1/auth/talent-forgot-password`;

  const resendOtpHandler = () => {


    axios
      .post(forgetPasswordURL, userEmail)

      .then((response) => {})
      .catch((error) => {

      });
  };
  const handleOnchange = (e) => {
    setOtpState(e.target.value);
  };

  const onSubmitHandler = (data) => {
    setIsLoading(true);
    dispatch({ type: "SET_TOKEN", payload: otpState });

    navigate("/set_new_password");
  };

  const togglePasswordHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="">
          <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
            <div className="'mx-auto bg-center">
              <img src={email} alt="" className="object-center" />
            </div>
            <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
              {" "}
              Check your email{" "}
            </h1>
          </div>
          <div className=" mx-auto  w-4/12 pb-4">
            <p className=" text-center subtext ">
              We sent a password reset code to your email
            </p>
            <p className=" text-center subtext font-semibold ">
              {" "}
              name@gmail.com
            </p>
          </div>
          <form>
            <div className=" mx-auto  w-4/12">
              <label htmlFor="">OTP</label>
              <div className="relative ">
                {passwordType === "password" ? (
                  <img
                    className="absolute right-4 translate-y-1/3"
                    src={hidden}
                    alt=""
                    onClick={togglePasswordHandler}
                  />
                ) : (
                  <img
                    className="absolute right-4 translate-y-1/3 "
                    src={eye}
                    alt=""
                    onClick={togglePasswordHandler}
                  />
                )}
                <input
                  type={passwordType}
                  value={otpState}
                  name="token"
                  required
                  className="form-control w-full font-poppins"
                  placeholder="e.g AQ1P5N"
                  onChange={handleOnchange}
                />
              </div>
              <p className="subtext mt-4">
                Please note that the OTP is case sensitive
              </p>

            </div>
            <div className="mx-auto w-4/12">
            <button
                id="my-button"
                onClick={onSubmitHandler}
                className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex  place-content-center"
              >
                {isLoading && (
                  <FaSpinner
                    isLoading={isLoading}
                    className={`mt-1 mr-2 spinner-1  `}
                  />
                )}
                {isLoading ? <span>Loading </span> : <span>Continue</span>}
              </button>
            </div>
          </form>

          <div className="mx-auto w-4/12 flex  place-content-center mt-4 ">
            <p className="mr-1 subtext text-[12px]">
              Didn’t receive the email?
            </p>
            <Link className="blue font-semibold" onClick={resendOtpHandler}>
              Click to resend
            </Link>
          </div>

          <button className=" flex mx-auto place-content-center mt-4 subtext lg:text-[12px]">
            <img src={arrowleft} alt="" /> Back to log in
          </button>

        </div>
      </Layout>
    </div>
  );
};

export default Forget_password_otp;