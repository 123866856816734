import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import Admin_header from "../../components/adminheader";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const ViewScore_writtenTest = () => {
  const params = useParams();
  const { applicationId } = params;
  const [token, setToken] = useState(() => localStorage.adminToken);
  const headers = {
    Authorization: "Bearer " + token,
  };
  const [questions, setQuestions] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const navigate = useNavigate();

  if (!localStorage.scoreOnce) {
    localStorage.setItem("scoreOnce", "true");
    setTimeout(window.location.reload.bind(window.location), 2000);
  }

  useEffect(() => {
    (async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-submission/${applicationId}?isGraded=true`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const resQuestions = res.data.data.docs;
          setQuestions(resQuestions);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    })();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/applications/${applicationId}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const data = res.data.data;
          setTalentData(data);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (localStorage.viewScore) {
      // Change color of gradeBox
      const totalScore = localStorage.getItem("viewScore");
      var total = totalScore * 25;
      if (total >= 60) {
        document.getElementById("gradeBox").style.backgroundColor = "#EFF9EF";
        document.getElementById("gradeText").style.color = "#2BA96F";
      } else {
        document.getElementById("gradeBox").style.backgroundColor = "#FFEAEA";
        document.getElementById("gradeText").style.color = "#AB0B0B";
      }

      // Change score
      var str = document.getElementById("gradeText").innerHTML;
      var res = str.replace(/25/g, totalScore + "/25");
      document.getElementById("gradeText").innerHTML = res;
    }
  }, []);

  return (
    <div className="allAuth none">
      <Layout>
        <div className="px-14">
          <Admin_header title={"Written Test Result"} />
          <ToastContainer autoClose={7000} />
          <div className="grid gap-y-8">
            <div
              className="border rounded-2xl p-8 grid grid-cols-2 mt-8 text-[#797488]"
              id="gradeBox"
            >
              <div>
                <h3 className="font-bold text-2xl text-black">
                  {" "}
                  {talentData?.talent?.firstName} {talentData?.talent?.lastName}
                </h3>
                <p> {talentData?.talent?.email}</p>
              </div>

              <div>
                <span>
                  <div className="text-[32px] -mb-2 text-center" id="gradeText">
                    25
                  </div>
                  <p className="text-center">points</p>
                </span>
              </div>
            </div>

            {questions?.map((question, index) => (
              <form className="grid gap-y-8" key={question?.id}>
                <div className="grid gap-y-4 pl-8">
                  <div className="flex text-xl -ml-8">
                    <div className="pr-4">{index + 1} </div>
                    <p>{question?.question} </p>
                  </div>

                  <p>Answer:</p>
                  <p>{question?.answer}</p>

                  {localStorage.setItem(
                    "viewScore",
                    Number(questions[0].score) +
                      Number(questions[1].score) +
                      Number(questions[2].score) +
                      Number(questions[3].score) +
                      Number(questions[4].score)
                  )}

                  <p>
                    Score: <b>{question?.score}</b>
                  </p>
                </div>
              </form>
            ))}

            <div className="flex mb-16">
              <button
                onClick={() =>
                  navigate(
                    `/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details/${applicationId}`
                  )
                }
                className="rounded-lg btn-outline"
              >
                View talent's progress
              </button>
              <button
                className="rounded-lg btn ml-auto"
                onClick={() =>
                  navigate(
                    "/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result"
                  )
                }
              >
                View all tests
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ViewScore_writtenTest;
