import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";

import {
  FiArchive,
  FiBookOpen,
  FiCheck,
  FiMic,
  FiUserCheck,
  FiX,
  FiArrowLeft,
} from "react-icons/fi";

import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const Talent_details = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { applicationId } = params;
  const [talentData, setTalentData] = useState([]);
  const [token, setToken] = useState(() => localStorage.adminToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/applications/${applicationId}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const data = res.data.data;
          setTalentData(data);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);


  return (
    <div className="none allAuth">
      <Layout>

        <div className="flex light mt-2 bg-base">
          <div className="">
            <div className="flex ml-8 mt-10">
              <FiArrowLeft
                className="text-2xl mt-1"
                onClick={() =>
                  navigate(
                    "/zedi/operations/mtaas-talent/admin/talent-dashboard"
                  )
                }
              />
              <h1 className="text-2xl pl-2 font-semibold texts">Talent</h1>
            </div>
            <div className="max-w-6xl flex-grow pb-6 bg-white border light mt-2 rounded-lg  mx-8 mb-8">
              <div className="flex px-6  my-6">
                <h1 className="text-2xl font-semibold">
                  {talentData?.talent?.firstName} {talentData?.talent?.lastName}
                </h1>
                <h2 className="ml-4 subtext pt-1  p-1 text-base">
                  {talentData?.talent?.country}{" "}
                </h2>
              </div>

              <div className="flex px-6 pb-4 place-content-between">
                <h3 className="mr-28 ">Email address</h3>
                <h3 className="ml-28 font-medium text-right">
                  {talentData?.talent?.email}
                </h3>
              </div>

              <div className="flex px-6 pb-4 place-content-between">
                <h3 className=" ">Phone number</h3>
                <h3 className=" font-medium ">
                  {talentData?.talent?.phoneNumber}
                </h3>
              </div>
              <div className="flex px-6 pb-4  place-content-between">
                <h3 className="">Location</h3>
                <h3 className="  font-medium ">{talentData?.talent?.state}</h3>
              </div>

              <div className="flex px-6 pb-4  place-content-between">
                <h3 className="">Role</h3>
                <h3 className=" font-medium">{talentData?.role}</h3>
              </div>

              <div className="flex px-6 pb-4  place-content-between">
                <h3 className="">Level</h3>
                <h3 className=" font-medium">{talentData?.level}</h3>
              </div>
            </div>

            <div className="max-w-4xl flex-grow bg-white border light mt-10 rounded-lg mx-8">
              <div className="flex px-4 my-6">
                <h1 className="text-2xl font-semibold">Test history</h1>
              </div>

              <div className={`flex mx-6 pb-4 place-content-between mb-2`}>
                <h3
                  className={`${
                    talentData?.basicFit === "Failed"
                      ? "fail"
                      : talentData?.basicFit === "Pending" ||
                        talentData?.basicFit === null
                      ? "pending"
                      : "pass"
                  } mr-28 flex`}
                >
                  <span className="blue text-lg gap-1">
                    <FiUserCheck
                      className={`${
                        talentData?.basicFit === "Failed"
                          ? "fail"
                          : talentData?.basicFit === "Pending" ||
                            talentData?.basicFit === null
                          ? "pending"
                          : "pass"
                      } mr-2 mt-1`}
                    />
                  </span>
                  Basic Fit Test
                </h3>
                <h1
                  className={`${
                    talentData?.basicFit === "Failed"
                      ? "test-failed"
                      : talentData?.basicFit === "Pending" ||
                        talentData?.basicFit === null
                      ? "test-pending"
                      : "test-passed"
                  }  pl-8 pt-1 font-medium`}
                >
                  {talentData?.basicFit}
                </h1>
              </div>

              <div className="flex mx-6 pb-4 place-content-between mb-2">
                <h3
                  className={`${
                    talentData?.verbal === "Failed"
                      ? "fail"
                      : talentData?.verbal === "Pending" ||
                        talentData?.verbal === null
                      ? "pending"
                      : "pass"
                  }  flex`}
                >
                  <span className=" text-lg gap-1">
                    <FiMic
                      className={`${
                        talentData?.verbal === "Failed"
                          ? "fail"
                          : talentData?.verbal === "Pending" ||
                            talentData?.verbal === null
                          ? "pending"
                          : "pass"
                      }  mr-2 mt-1`}
                    />
                  </span>
                  Verbal Test
                </h3>
                <h1
                  className={`${
                    talentData?.verbal === "Failed"
                      ? "test-failed"
                      : talentData?.verbal === "Pending" ||
                        talentData?.verbal === null
                      ? "test-pending"
                      : "test-passed"
                  }  pl-8 pt-1 font-medium`}
                >
                  {talentData?.verbal}
                </h1>
              </div>
              <div className="flex mx-6 pb-4  place-content-between mb-2">
                <h3
                  className={`${
                    talentData?.written === "Failed"
                      ? "fail"
                      : talentData?.written === "Pending" ||
                        talentData?.written === null
                      ? "pending"
                      : "pass"
                  }  flex `}
                >
                  <span className=" text-lg gap-1">
                    <FiBookOpen
                      className={`${
                        talentData?.written === "Failed"
                          ? "fail"
                          : talentData?.written === "Pending" ||
                            talentData?.written === null
                          ? "pending"
                          : "pass"
                      }  mr-2 mt-1`}
                    />
                  </span>
                  Written Test
                </h3>
                <h1
                  className={`${
                    talentData?.written === "Failed"
                      ? "test-failed"
                      : talentData?.written === "Pending" ||
                        talentData?.written === null
                      ? "test-pending"
                      : "test-passed"
                  }  pl-8 pt-1 font-medium`}
                >
                  {talentData?.written}
                </h1>
              </div>

              <div className="flex mx-6 pb-4  place-content-between mb-2">
                <h3
                  className={`${
                    talentData?.roleSpecific === "Failed"
                      ? "fail"
                      : talentData?.roleSpecific === "Pending" ||
                        talentData?.roleSpecific === null
                      ? "pending"
                      : "pass"
                  }  flex`}
                >
                  <span className="text-lg ">
                    <FiArchive className="mr-2 mt-1" />
                  </span>
                  Role Specific test
                </h3>
                <h1
                  className={`${
                    talentData?.roleSpecific === "Failed"
                      ? "test-failed"
                      : talentData?.roleSpecific === "Pending" ||
                        talentData?.roleSpecific === null
                      ? "test-pending"
                      : "test-passed"
                  }  pl-8 pt-1 font-medium`}
                >
                  {talentData?.roleSpecific}
                </h1>
              </div>
            </div>
          </div>

          <div className="max-w-xl  flex-grow px-8  bg-white border light mt-20 pt-6 pb-28 rounded-lg ">
            {talentData?.stage === "basic_fit" ? (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (1/5)
              </h1>
            ) : talentData?.stage === "speech" ? (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (2/5)
              </h1>
            ) : talentData?.stage === "written" ? (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (3/5)
              </h1>
            ) : talentData?.stage === "role_specific" ? (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (4/5)
              </h1>
            ) : talentData?.stage === "accepted" ? (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (5/5)
              </h1>
            ) : (
              <h1 className="text-2xl font-semibold mb-6">
                Onboarding process (0/0)
              </h1>
            )}

            <div className="flex">
              {talentData?.basicFit === "Failed" ? (
                <button className="btn-fail mt-1 mb-4  ">
                  <FiX className=" ml-2 pl-1 text-2xl" />
                </button>
              ) : talentData?.basicFit === "Pending" ||
                talentData?.basicFit === null ? (
                <button className="btn-pending mt-1 mb-4 font-bold">1</button>
              ) : (
                <button className="btn-pass mt-1 mb-4">
                  <FiCheck className=" ml-2 pl-1 text-2xl" />
                </button>
              )}
              <div className="progress" style={{ width: "4px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "10", backgroundColor: "#E0E0E0" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="ml-6 mt-3">
                <h2 className="font-bold text-base">Basic Fit Test</h2>
              </div>
            </div>

            <div className="flex">
              {talentData?.verbal === "Failed" ? (
                <button className="btn-fail mt-1 mb-4 ">
                  <FiX className=" ml-2 pl-1 text-2xl" />
                </button>
              ) : talentData?.verbal === "Pending" ||
                talentData?.verbal === null ? (
                <button className="btn-pending mt-1 mb-4 font-bold">2</button>
              ) : (
                <button className="btn-pass mt-1 mb-4 ">
                  <FiCheck className="ml-2 pl-1 text-2xl" />
                </button>
              )}
              <div className="progress" style={{ width: "4px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "10", backgroundColor: "#E0E0E0" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="ml-6">
                <h2 className="font-bold text-base mt-3">Verbal Test</h2>
              </div>
            </div>
            <div className="flex">
              {talentData?.written === "Failed" ? (
                <button className="btn-fail mt-1 mb-4 ">
                  <FiX className="ml-2 pl-1 text-2xl" />
                </button>
              ) : talentData?.written === "Pending" ||
                talentData?.written === null ? (
                <button className="btn-pending mt-1 mb-4 font-bold">3</button>
              ) : (
                <button className="btn-pass mt-1 mb-4">
                  <FiCheck className="ml-2 pl-1 text-2xl" />
                </button>
              )}
              <div className="progress" style={{ width: "4px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "10", backgroundColor: "#E0E0E0" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="ml-6">
                <h2 className="font-bold text-base mt-3">Written Test</h2>
              </div>
            </div>
            <div className="flex">
              {talentData?.roleSpecific === "Failed" ? (
                <button className="btn-fail mt-1 mb-4 ">
                  <FiX className="ml-2 pl-1 text-2xl" />
                </button>
              ) : talentData?.roleSpecific === "Pending" ||
                talentData?.roleSpecific === null ? (
                <button className="btn-pending mt-1 mb-4 font-bold">4</button>
              ) : (
                <button className="btn-pass mt-1 mb-4">
                  <FiCheck className="ml-2 pl-1 text-2xl" />
                </button>
              )}
              <div className="progress" style={{ width: "4px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "10", backgroundColor: "#E0E0E0" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="ml-6">
                <h2 className="font-bold text-base mt-3">Rolespecific Test</h2>
              </div>
            </div>
            <div className="flex mb-32">
              {talentData?.stage === "accepted" ? (
                <button className="btn-pass mt-1 mb-4">
                  <FiCheck className="ml-2 pl-1 text-2xl" />
                </button>
              ) : (
                <button className="btn-pending mt-1 mb-4 font-bold">5</button>
              )}
              <div className="progress" style={{ width: "4px" }}>
                <div
                  className="progress-bar"
                  role="progressbar"
                  style={{ width: "10", backgroundColor: "#E0E0E0" }}
                  aria-valuenow="50"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
              <div className="ml-6">
                <h2 className="font-bold text-base mt-3">Onboarding</h2>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Talent_details;
