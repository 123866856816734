import React from "react";
import error from "../assets/icons/error.svg";

const viewportError = () => {
  return (
    <div className="grid vpError w-screen h-screen items-center">
      <div className="bg-white rounded-2xl grid gap-y-4 p-8 m-auto text-center sm:w-2/4 w-3/4">
        <img src={error} alt="" className="mx-auto" />
        <h1 className="font-bold text-2xl">Oops!</h1>
        <p className="subtext">
          It looks like you’re viewing this on a mobile browser, please use a
          supported desktop browser to get the best experience.
        </p>
        <button className="btn-outline">Got It</button>
      </div>
    </div>
  );
};

export default viewportError;
