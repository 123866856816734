import React from "react";
import { Link } from "react-router-dom";
import { FiArrowLeft } from "react-icons/fi";

const Admin_header = (props) => {
  return (
    <div className="flexfont-poppins mt-10 ">
      <div className="text-lg lg:text-2xl">
        <Link
          to="/zedi/operations/mtaas-talent/admin/test-dashboard"
          className="self-center flex"
        >
          <FiArrowLeft className="self-center mr-2" size={24} />
          <p className="text-[#4E4A5A] text-2xl font-medium">{props.title}</p>
        </Link>
      </div>
    </div>
  );
};

export default Admin_header;
