import React from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";

const Terms_Conditions = () => {
  return (
    <div className="hiddenPages tc">
      <Layout>
        <div className="test_guidelines tc font-poppins">
          <Banner title="Hirefoster by Zedi Terms and Conditions" />
          <div className="px-8 lg:px-32 mx-20 pb-28 pt-16  bg-[#F5F3FB] my-10">
            <p className="texts leading-10  text-[18px] pt-4 mb-6">
              These Terms and Conditions (&quot;Agreement&quot;) govern the use
              of Hirefoster by Zedi, a recruitment agency. Hirefoster by Zedi is
              the recruitment arm of Zedi Africa Limited (&quot;Company&quot;).
              By using the Hirefoster website or taking the talent test, you
              (&quot;User&quot; or &quot;You&quot;) agree to be bound by the
              terms and conditions set forth in this Agreement.
            </p>
            <p className="texts leading-10 font-semibold text-[18px] mb-6">
              1. Acceptance of Terms
            </p>
            <p className="texts leading-10  text-[18px]">
              <strong>1.1 </strong> By accessing the Website or taking the
              talent test, you acknowledge that you have read, understood, and
              agree to be bound by this Agreement. If you do not agree to these
              terms, you must immediately cease using the Website.
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>1.2 </strong> The Company reserves the right to modify or
              update this Agreement at any time without prior notice. Your
              continued use of the Website after any such changes constitutes
              your acceptance of the revised Agreement.
            </p>
            <p className="texts leading-10 font-semibold text-[18px] my-6">
              2. Hirefoster by Zedi Services
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.1 </strong> The company is in the business of talent
              management and outsourcing.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.2 </strong> The company connects growth, sales and
              marketing talents to businesses.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>2.3 </strong> The company stands as an intermediary
              between talents and clients.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              3. User Obligations
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>3.1 </strong> You must be at least 18 years old to take
              our talent test. The Company disclaims any liability from Users
              under the age of 18 to take the talent test.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>3.2 </strong> You agree to provide accurate, current, and
              complete information during the registration process and maintain
              the accuracy of this information.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>3.3 </strong> You agree not to use the Hirefoster by Zedi
              Website for any illegal, unauthorized, or improper purposes,
              including but not limited to transmitting any harmful or
              disruptive content or engaging in fraudulent activities.
            </p>
            <p className="texts leading-10 font-semibold text-[18px] my-6">
              4. Intellectual Property
            </p>

            <p className="texts leading-10 text-[18px] mt-4">
              <strong>4.1 </strong> The Hirefoster by Zedi Website and all its
              contents, including but not limited to text, graphics, logos,
              images, software, and audio or video clips, are the property of
              the Company or its licensors and are protected by intellectual
              property laws.
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>4.2 </strong> You may not modify, reproduce, distribute,
              create derivative works, or reverse engineer any part of the
              Hirefoster by Zedi Website without the express written permission
              of the Company.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              5. Privacy
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>5.1 </strong> Hirefoster by Zedi collects, stores, and
              uses personal information in accordance with its Privacy Policy.
              By using the Website, you consent to the collection, storage, and
              use of your personal information as described in the Privacy
              Policy.
            </p>

            <p className="texts leading-10 font-semibold text-[18px] my-6">
              6. Disclaimer of Warranty
            </p>
            <p className="texts leading-10 text-[18px] mt-4">
              <strong>6.1 </strong> The Hirefoster by Zedi Website is provided
              on an &quot;as is&quot; and &quot;as available&quot; basis without
              warranties of any kind, whether express or implied.
            </p>
          </div>
          <div className="grid mx-20">
            <div>
              <Link to="/">
                <button className="btn float-right w-full lg:w-96 flex justify-center">
                  <span className="pt-1 self-center">Go Back</span>
                  <HiArrowRight className="self-center ml-2" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Terms_Conditions;
