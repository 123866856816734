import React, { useReducer } from "react";
import AuthContext from "./auth_context";
import Auth_context_reducer from "./auth_context_reducer";

const AuthContextState = (props) => {
  const initialState = {
    userEmail: null,
    userToken: null,
    userData: null,
  };

  const [state, dispatch] = useReducer(Auth_context_reducer, initialState);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextState;
