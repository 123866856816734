import React, { useState, useEffect, } from "react";
import Layout from "../../components/layout";
import signed from "../../assets/images/talent-signed.svg";
import successful from "../../assets/images/check-mark.svg";
import progress from "../../assets/images/in-progress.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Talent_dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [token, setToken] = useState(() => localStorage.adminToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/applications/overview`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const data = res.data.data;
          setData(data);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results?skip=0&limit=10`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const dataInfo = res.data.data.docs;
          setTalentData(dataInfo);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);

  return (
    <div className=" allAuth none font-poppins">
      <Layout>
        <div className="">
          <div className=" light mt-2 bg-base flex-1">
            <h1 className="text-2xl pl-1 lg:pl-9 pt-5 font-semibold texts">Talents</h1>
            <div className="grid grid-flow-row lg:grid-flow-col">
              <div className="max-w-md p-6 bg-white border light mt-5 rounded-lg ml-1 lg:ml-10 mr-3">
                <div className="flex ">
                  <img className="pl-3 pt-2" src={signed} alt="" />
                  <p className="pl-2 pt-5 text-base subtext">
                    Registered talents
                  </p>
                </div>
                <h1 className="pl-5 pt-3 text-2xl font-semibold">
                  {data.totalTalents}
                </h1>
              </div>

              <div className="max-w-md  p-6 bg-white border light mt-5 rounded-lg ml-1 lg:ml-0 mr-3">
                <div className="flex p">
                  <img className="pl-3 pt-2" src={progress} alt="" />
                  <p className="pl-2 pt-5 text-base subtext">Pending talents</p>
                </div>
                <h1 className="pl-5 pt-3 text-2xl font-semibold">
                  {data.totalTalentsInProgress}
                </h1>
              </div>

              <div className="max-w-md  p-6 bg-white border light mt-5 rounded-lg ml-1 lg:ml-0 mr-3">
                <div className="flex p">
                  <img className="pl-3 pt-2" src={successful} alt="" />
                  <p className="pl-2 pt-5 text-base subtext">
                    Successful talents
                  </p>
                </div>
                <h1 className="pl-5 pt-3 text-2xl font-semibold">
                  {data.totalAcceptedTalents}
                </h1>
              </div>
            </div>

            <div className="flex place-content-between mr-3 lg:mr-32 mt-5">
              <h2 className="text-xs lg:text-xl mt-10 ml-1 lg:ml-10 font-medium lg:font-semibold">
                Registered talents
              </h2>
              <button
                onClick={() =>
                  navigate(
                    "/zedi/operations/mtaas-talent/admin/talent-dashboard/registered_talents"
                  )
                }
                className="blue mt-9 px-2 text-xs  lg:px-6 text-center  lg:text-base lg:font-medium"
              >
                View all talents
              </button>
            </div>
            <div className="mt-10 ml-1 lg:ml-10 grid grid-cols-4 bg-line pb-1">
            <div className="pt-2 w-3/12 ml-4 lg:ml-20">
              <h1 className="texts text-xs  lg:text-base">NAME</h1>
            </div>

            <div className="pt-2 w-6/12 ml-6 lg:ml-20 ">
              <h1 className="texts text-xs lg:text-base">TEST </h1>
            </div>

            <div className="pt-2 w-3/12  ml-2 lg:ml-0">
              <h1 className="texts text-xs lg:text-base">RESULT</h1>
            </div>

            <div className="pt-2 w-3/12   ml-2 lg:ml-0">
              <h1 className="texts text-xs lg:text-base invisible">RESULT</h1>
            </div>

          </div>

            {talentData?.map((talents) => (
              <div className="mt-4 ml-1 lg:ml-10 grid grid-cols-4  ">
                <div className=" w-3/12 ml-4 lg:ml-20 mb-4 pt-6 ">
                <h1 className=" text-xs lg:text-xl font-medium">
                  {talents.talent.firstName}
                </h1>
                <p className="invisible lg:visible">{talents.talent.email}</p>
              </div>

              <div className=" w-6/12 ml-6 lg:ml-20 pt-7 lg:pt-8">
                <h1 className="text-xs lg:text-base">{talents.stage}</h1>
              </div>


              <div
                className={`${
                  talents.status === "Failed"
                    ? "test-failed-1 lg:test-failed"
                    : talents.status === "Pending" || talents.status === null
                    ? "test-pending-1 lg:test-pending"
                    : "test-passed-1 lg:test-passed"
                }   w-3/12 ml-2 lg:ml-0  text-xs lg:text-xs mt-6 pt-1`}
              >
                <h1 className="text-center pt-1">{talents.status}</h1>
              </div>
                <div className="">
                  <button
                    onClick={
                      () =>
                        navigate(
                          `/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details/${talents.applicationId}`
                        )

                    }
                    className=" text-xs lg:text-base  blue  lg:btn-outline-2 px-4 ml-5 lg:ml-10 mt-8  lg:mt-7"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Talent_dashboard;
