import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
import { FiArrowLeft, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Registered_talents = () => {
  const [talentData, setTalentData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(() => localStorage.adminToken);
  const [skip, setSkip] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10
  const [selectedValue, setSelectedValue] = useState(null);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const handleSelectChange = (event) => {
    if (event.target.value !== "all") {
      setSelectedValue(event.target.value);
    } else {
      setSelectedValue(null);
    }
  };

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results?${
        selectedValue === null ? "" : `stage=${selectedValue}`
      }&skip=${skip}&limit=${limit}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const dataInfo = res.data.data.docs;
          setTalentData(dataInfo);
          setTotalPages(res.data.data.totalPages);

        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token, skip, selectedValue]);


  const handlePageChange = (event) => {
    setSkip(event.selected * limit);
  };

  return (
    <div className="  allAuth none font-poppins">
      <Layout>
        <div className="">


          <div className=" light flex-1 mt-2 bg-base ">
          <div className="flex place-content-between mr-20">
              <div className="flex ml-1 lg:ml-28 mt-10">
                <FiArrowLeft
                  className="text-2xl mt-1"
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard"
                    )
                  }
                />
                <h1 className="text-lg lg:text-2xl pl-2 pt-1 lg:pt-0 font-semibold texts">
                 Registered talents
                </h1>
              </div>
              <div className="flex">
                <div className="mt-9 filter flex place-content-between mr-6">
                  <h3 className="mt-3 ml-3 font-medium">STAGES</h3>

                  <select
                    className="mr-6"
                    value={selectedValue}
                    onChange={handleSelectChange}
                  >
                    <option name="" value="all">
                      All stages
                    </option>
                    <option name="basic_fit" value="basic_fit">
                      Basic fit
                    </option>
                    <option name="speech" value="speech">
                      Speech
                    </option>
                    <option name="written" value="written">
                      Written
                    </option>
                    <option name="role_specific" value="role_specific">
                      Role specific
                    </option>
                    <option name="accepted" value="accepted">
                      Accepted
                    </option>
                  </select>
                </div>

                
              </div>
            </div>

            <div className="mt-10 ml-1 lg:ml-10 grid grid-cols-4 bg-line pb-1">
            <div className="pt-2 w-3/12 ml-4 lg:ml-20">
              <h1 className="texts text-xs  lg:text-base">NAME</h1>
            </div>

            <div className="pt-2 w-6/12 ml-6 lg:ml-20 ">
              <h1 className="texts text-xs lg:text-base">TEST </h1>
            </div>

            <div className="pt-2 w-3/12  ml-2 lg:ml-0">
              <h1 className="texts text-xs lg:text-base">RESULT</h1>
            </div>

            <div className="pt-2 w-3/12   ml-2 lg:ml-0">
              <h1 className="texts text-xs lg:text-base invisible">RESULT</h1>
            </div>
            </div>

            {talentData?.map((talents) => (
              <div className="mt-4 ml-1 lg:ml-10 grid grid-cols-4  " key={talents.talent.id}>
                <div className=" w-3/12 ml-4 lg:ml-20 mb-4 pt-6 ">
                <h1 className=" text-xs lg:text-xl font-medium">
                  {talents.talent.firstName}
                </h1>
                <p className="invisible lg:visible">{talents.talent.email}</p>
              </div>

              <div className=" w-6/12 ml-6 lg:ml-20 pt-7 lg:pt-8">
                <h1 className="text-xs lg:text-base">{talents.stage}</h1>
              </div>


              <div
                className={`${
                  talents.status === "Failed"
                    ? "test-failed-1 lg:test-failed"
                    : talents.status === "Pending" || talents.status === null
                    ? "test-pending-1 lg:test-pending"
                    : "test-passed-1 lg:test-passed"
                }   w-3/12 ml-2 lg:ml-0  text-[8px] lg:text-xs mt-6 pt-1`}
              >
                <h1 className="text-center pt-1">{talents.status}</h1>
              </div>
                <div className="">
                  <button
                    onClick={
                      () =>
                        navigate(
                          `/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details/${talents.applicationId}`
                        )

                    }
                    className=" text-xs lg:text-base  btn-outline-1  lg:btn-outline-2 px-4 ml-5 lg:ml-10  mt-5"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}
            <ReactPaginate
              className="flex place-content-center mx-auto my-8"
              breakLabel="..."
              nextLabel={<FiChevronRight className="text-3xl " />}
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={totalPages}

              previousLabel={<FiChevronLeft className="text-3xl " />}
              previousClassName={""}
              previousLinkClassName="text-xs text-blue"
              nextClassName=""
              disabledClassName="border-gray-400"
              disabledLinkClassName="text-gray-400"
              nextLinkClassName="text-xs text-blue"
              containerClassName="flex"
              pageClassName="subtext mx-2 text-xl"
              activeClassName="next-active "
              activeLinkClassName="text-blue"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Registered_talents;
