import React, { useState } from "react";
import Layout from "../../components/user_layout";
import admin from "../../assets/images/admin.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import axios from "axios";
import eye from "../../assets/images/eye.svg";
import hidden from "../../assets/images/eye-off.svg";
import { FaSpinner } from "react-icons/fa";

const Admin_login = () => {
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  const schema = yup.object().shape({
    email: yup.string().email("Not a valid email").required("Required"),
    password: yup.string().min(8).required(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const adminLoginURL = `${process.env.REACT_APP_API_URL}/v1/auth/admin-signin`;

  const onSubmitHandler = (data) => {
    setIsLoading(true);
    axios
      .post(adminLoginURL, data)
      .then(function (response) {
        setIsLoading(false);
        const res = response.data.data;
        localStorage.setItem("adminToken", response.data.data.token);
        localStorage.setItem("firstName", res.user.firstName);

        window.location.href = "/zedi/operations/mtaas-talent/admin/dashboard";
      })
      .catch(function (error) {
        setIsLoading(false);
        setError("apiError", { message: error });
      });
  };
  const togglePasswordHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="auth">
          <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center ">
            <div className="'mx-auto bg-center">
              <img src={admin} alt="" className="object-center" />
            </div>
            <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
              {" "}
              Admin Login
            </h1>
          </div>
          <form className="" onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="  w-full lg:w-4/12 lg:mx-auto  mb-8 ">
              <label htmlFor="">Email</label> <br />
              <input
                type="email"
                name="email"
                required
                className="form-control w-full mt-2 font-poppins"
                placeholder="e.g 0123name@email.com45"
                {...register("email", { required: true })}
              />
              <p className="text-red-600 font-bold">
                {errors.apiError && "please insert your correct email"}
              </p>
            </div>
            <div className=" w-full lg:w-4/12 lg:mx-auto">
              <label htmlFor="">Password</label>
              <div className="relative">
                {passwordType === "password" ? (
                  <img
                    className="absolute right-4 translate-y-1/3"
                    src={hidden}
                    alt=""
                    onClick={togglePasswordHandler}
                  />
                ) : (
                  <img
                    className="absolute right-4 translate-y-1/3 "
                    src={eye}
                    alt=""
                    onClick={togglePasswordHandler}
                  />
                )}
                <input
                  type={passwordType}
                  name="password"
                  required
                  className="form-control w-full  font-poppins"
                  placeholder="Password"
                  {...register("password", { required: true })}
                />
              </div>
              <p className="text-red-600 font-bold ">
                {errors.apiError && "please insert your correct password"}
              </p>
            </div>

            <div className="w-full lg:w-4/12 lg:mx-auto">
              <button
                id="my-button"
                className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex mx-auto place-content-center"
              >
                {isLoading && (
                  <FaSpinner
                    isLoading={isLoading}
                    className={`mt-1 mr-2 spinner-1  `}
                  />
                )}
                {isLoading ? <span>Logging in</span> : <span>Login</span>}
              </button>
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Admin_login;
