import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
import { FiArrowLeft, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ReactPaginate from "react-paginate";
import { useNavigate } from "react-router-dom";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Cmpleted_test_results = () => {
  const [talentData, setTalentData] = useState([]);
  const [token, setToken] = useState(() => localStorage.adminToken);
  const [skip, setSkip] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;
  const headers = {
    Authorization: "Bearer " + token,
  };
  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results?stage=accepted&skip=${skip}&limit=${limit}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          if (res.status === "error" && res.message === "unauthorized") {
            navigate("/zedi/operations/mtaas-talent/admin/login");
          }
          const dataInfo = res.data.data.docs;

          setTalentData(dataInfo);
          setTotalPages(res.data.data.totalPages);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token, skip]);

  const handlePageChange = (event) => {
    setSkip(event.selected * limit);
  };

  return (
    <div className="  allAuth none font-poppins">
      <Layout>
        <div className="">

          <div className=" light flex-1 mt-2 bg-base ">
            <div className="flex ml-1 lg:ml-28 mt-10">
              <FiArrowLeft
                className="text-2xl mt-1"
                onClick={() =>
                  navigate("/zedi/operations/mtaas-talent/admin/test-dashboard")
                }
              />
              <h1 className="text-lg lg:text-2xl pt-1 lg:pt-0 pl-2 font-semibold texts">
                Completed tests
              </h1>
            </div>

            <div className="mt-10 ml-1 lg:ml-10 grid grid-cols-3 bg-line pb-1">
              <div className="pt-2 w-3/12 ml-4 lg:ml-20">
                <h1 className="texts text-xs  lg:text-base">NAME</h1>
              </div>

              <div className="pt-2 w-6/12 ml-6 lg:ml-0 ">
                <h1 className="texts text-xs lg:text-base">TEST </h1>
              </div>
            </div>

            {talentData?.map((talents) => (
              <div className="mt-4 ml-10 grid grid-cols-3  ">
                <div className=" w-3/12 ml-20 mb-4 pt-6 ">
                  <h1 className="text-xl font-medium">
                    {talents.talent.firstName}
                  </h1>
                  <p>{talents.talent.email}</p>
                </div>

                <div className="w-6/12  pt-8">
                  <h1 className="text-base">{talents.stage}</h1>
                </div>

                <div className="">
                  <button
                    onClick={
                      () =>
                        navigate(
                          `/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details/${talents.applicationId}`
                        )

                    }
                    className=" text-xs lg:text-base  blue  lg:btn-outline-2 px-4 ml-5 lg:ml-10 mt-8  lg:mt-7"
                  >
                    View
                  </button>
                </div>
              </div>
            ))}

            <ReactPaginate
              className="flex place-content-center mx-auto my-8"
              breakLabel="..."
              nextLabel={<FiChevronRight className="text-3xl " />}
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={totalPages}

              previousLabel={<FiChevronLeft className="text-3xl " />}
              previousClassName={""}
              previousLinkClassName="text-xs text-blue"
              nextClassName=""
              disabledClassName="border-gray-400"
              disabledLinkClassName="text-gray-400"
              nextLinkClassName="text-xs text-blue"
              containerClassName="flex"
              pageClassName="subtext mx-2 text-xl"
              activeClassName="next-active "
              activeLinkClassName="text-blue"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Cmpleted_test_results;
