import React from "react";
import Layout from "../../components/user_layout";
import failure from "../../assets/icons/failure.svg";

const Failure = () => {
  return (
    <div className="confirmation font-poppins">
      <Layout>
        <div className="mx-auto text-center w-screen content-center px-8 pt-14 md:px-0 md:w-9/12">
          <img src={failure} alt="" className="mx-auto w-3/12" />
          <h1 className="font-bold text-[32px] pt-6 pb-3">
            Woah, looks like something went wrong
          </h1>
          <p className="subtext text-[16px] mb-14">
            It is most likely our fault this time, reach out and let us know how
            you go to this page: hello@zedi.africa
          </p>
          <a className="btn my-10 !px-16" href="mailto:hello@zedi.africa">
            Send an Email
          </a>
        </div>
      </Layout>
    </div>
  );
};

export default Failure;
