import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Sign_up from "./pages/signup";
import Login from "./pages/login";
import Test_guidelines from "./pages/test_guidelines";
import Auth_confirmation from "./pages/auth_confirmation";
import Email_confirmation from "./pages/email_confirmation";
import Not_found from "./pages/not_found";
import Forget_password from "./pages/forget_password";
import Forget_password_otp from "./pages/forget_password_otp";
import Set_new_password from "./pages/set_new_password";
import Password_reset_success from "./pages/password_reset_success";
import AuthContextState from "./context/authcontext/auth_context_state";
import Speech_test from "./pages/speech_test";
import Speech_test_guidelines from "./pages/speech_test_guidelines";
import Submit_voice_test from "./pages/submit_voice_test";
import Admin_login from "./pages/admin/admin_login";
import Written_test_guidelines from "./pages/written_test_guidelines";
import Written_test from "./pages/written_test";
import Role_specific_test_guidelines from "./pages/role_specific_test_guidelines";
import Role_specific_test from "./pages/role_specific_test";
import Basic_fit_Test from "./pages/test_one";
import Success from "./pages/status/success";
import Failure from "./pages/status/failure";
import Speech_failure from "./pages/speech_failure";
import Dashboard from "./pages/admin/dashboard";
import Create_Test from "./pages/admin/create_test";
import Basicfit_adding_questions from "./pages/admin/basicfit_add_questions.jsx";
import Speech_add_question from "./pages/admin/speech_add_question";
import Test_dashboard from "./pages/admin/test_dashboard";
import Admin_signup from "./pages/admin/admin_signup";
import Talent_dashboard from "./pages/admin/talent_dashboard";
import Talent_details from "./pages/admin/talent_details";
import Rolespecific_add_question from "./pages/admin/rolespecific_add_question";
import Written_add_question from "./pages/admin/written_test_add_questions";
import Basicfit_test_results from "./pages/admin/basicfit_test_results";
import Written_test_result from "./pages/admin/written_test_result";
import Rolespecific_test_result from "./pages/admin/rolespecific_test_result";
import Test_results from "./pages/admin/test_results";
import Registered_talents from "./pages/admin/registered_talents";
import Completed_test_results from "./pages/admin/completed_test_results";
import Speech_test_results from "./pages/admin/speech_test_results";
import Score_writtenTest from "./pages/admin/score_writtenTest";
import Score_roleSpecificTest from "./pages/admin/score_roleSpecific_test";
import ViewScore_writtenTest from "./pages/admin/viewScore_writtenTest";
import ViewScore_roleSpecificTest from "./pages/admin/viewScore_roleSpecificTest";
import Terms_Conditions from "./pages/terms_conditions";
import Test_mic from "./pages/test_mic";
import Privacy_Policy from "./pages/privacy_policy";

function App() {
  return (
    <AuthContextState>
      <Router>
        <Routes>
          <Route exact path="/" element={<Sign_up />} />
          <Route
            exact
            path="/terms_conditions"
            element={<Terms_Conditions/>}
          />
          <Route
            exact
            path="/policy_privacy"
            element={<Privacy_Policy/>}
          />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/auth_confirmation"
            element={<Auth_confirmation />}
          />
          <Route
            exact
            path="/email_confirmation"
            element={<Email_confirmation />}
          />
          <Route exact path="/status/success" element={<Success />} />
          <Route exact path="/status/failure" element={<Failure />} />

          <Route exact path="/test_guidelines" element={<Test_guidelines />} />
          <Route exact path="/basic_fit_test" element={<Basic_fit_Test />} />
          <Route exact path="/forget_password" element={<Forget_password />} />
          <Route
            exact
            path="/forget_password_otp"
            element={<Forget_password_otp />}
          />
          <Route
            exact
            path="/set_new_password"
            element={<Set_new_password />}
          />
          <Route
            exact
            path="/password_reset_success"
            element={<Password_reset_success />}
          />
          <Route exact path="/tests/speech-test" element={<Speech_test />} />
          <Route
            exact
            path="/speech_test_guidelines"
            element={<Speech_test_guidelines />}
          />
          <Route
            exact
            path="/submit_voice_test"
            element={<Submit_voice_test />}
          />
          <Route exact path="/speech_failure" element={<Speech_failure />} />

          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/signup"
            element={<Admin_signup />}
          />

          <Route
            exact
            path="/written_test_guidelines"
            element={<Written_test_guidelines />}
          />
          <Route exact path="/written_test" element={<Written_test />} />
          <Route
            exact
            path="/role_specific_test_guidelines"
            element={<Role_specific_test_guidelines />}
          />
          <Route
            exact
            path="/role_specific_test"
            element={<Role_specific_test />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/login"
            element={<Admin_login />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/dashboard"
            element={<Dashboard />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard"
            element={<Test_dashboard />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/talent-dashboard"
            element={<Talent_dashboard />}
          ></Route>
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/talent-dashboard/talent-details/:applicationId"
            element={<Talent_details />}
          />
          <Route exact path="*" element={<Not_found />} />

          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/create_test"
            element={<Create_Test />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/basicfit_add_questions"
            element={<Basicfit_adding_questions />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/speech_add_question"
            element={<Speech_add_question />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_add_question"
            element={<Rolespecific_add_question />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_add_questions"
            element={<Written_add_question />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/test_result"
            element={<Test_results />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/basicfit_test_results"
            element={<Basicfit_test_results />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/speech_test_results"
            element={<Speech_test_results />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result"
            element={<Written_test_result />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_test_result"
            element={<Rolespecific_test_result />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/completed_test_results"
            element={<Completed_test_results />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/talent-dashboard/registered_talents"
            element={<Registered_talents />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result/view_score/:applicationId"
            element={<ViewScore_writtenTest />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_test_result/view_score/:applicationId"
            element={<ViewScore_roleSpecificTest />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result/score/:applicationId"
            element={<Score_writtenTest />}
          />
          <Route
            exact
            path="/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_test_result/score/:applicationId"
            element={<Score_roleSpecificTest />}
          />

<Route
            exact
            path="/test_mic"
            element={<Test_mic />}
          />
        </Routes>
      </Router>
    </AuthContextState>
  );
}

export default App;
