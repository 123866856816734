import * as React from "react";
import PropTypes from "prop-types";
import Navbar from "./navbar";
import Footer from "./footer";
import ViewportError from "./viewportError";

const Admin_layout = ({ children }) => {
  return (
    <>
      <ViewportError />
      <Navbar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

Admin_layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Admin_layout;
