import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const CounterTwo = () => {
  const navigate = useNavigate();
  const toastID = Math.random();
  const duration =
    window.location.pathname === "/tests/speech-test"
      ? 180
      : window.location.pathname === "/role_specific_test" ||
        window.location.pathname === "/written_test"
      ? 900
      : 600;
  const [timeDuration, setTimeDuration] = useState(duration);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeDuration(timeDuration - 1);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [timeDuration]);

  useEffect(() => {
    if (timeDuration <= 0 || timeDuration === 0) {
        toast.error("Time's up! \n Sorry your time has run out. \n Click ok to Submit", {toastId: toastID})

        let submitBtn = document.getElementById("submitBtn"); 
        if (submitBtn) {
          submitBtn.click();
          navigate("/status/success");
        }else {
          navigate("/status/failure");
        }
      }
      //eslint-disable-next-line
  }, [timeDuration])

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `00:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <>
      <ToastContainer />
      <div id="timer">{formatTime(timeDuration)}</div>
    </>
  );
};

export default CounterTwo;
