import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FiArrowLeft, FiChevronLeft, FiChevronRight } from "react-icons/fi";
import ReactPaginate from "react-paginate";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Speech_test_results = () => {
  const [talentData, setTalentData] = useState([]);
  const [token, setToken] = useState(() => localStorage.adminToken);
  const [skip, setSkip] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const limit = 10;
  const [selectedValue, setSelectedValue] = useState(null);
  const headers = {
    Authorization: "Bearer " + token,
  };
  const navigate = useNavigate();


  const handleSelectChange = (event) => {
    if (event.target.value !== "All") {
      setSelectedValue(event.target.value);
    } else {
      setSelectedValue(null);
    }

  };

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results/speech?${
        selectedValue === null ? "" : `passed=${selectedValue}`
      }&skip=${skip}&limit=${limit}`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const dataInfo = res.data.data.docs;
          setTalentData(dataInfo);
          setTotalPages(res.data.data.totalPages);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token, skip, selectedValue]);

  const handlePageChange = (event) => {
    setSkip(event.selected * limit);
  };

  return (
    <div className="  allAuth none font-poppins">
      <Layout>
        <div className="flex">
          <div className=" light flex-1 mt-2 bg-base ">
          <div className="flex place-content-between mr-28">
              <div className="flex ml-1 lg:ml-28 mt-10">
                <FiArrowLeft
                  className="text-2xl mt-1"
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard"
                    )
                  }
                />
                <h1 className="text-lg lg:text-2xl pl-2 pt-1 lg:pt-0 font-semibold texts">
                  Speech test
                </h1>
              </div>
              <div className="mt-9 filter flex place-content-between">
                <h3 className="mt-3 ml-3 font-medium">STATUS</h3>


                <select
                  className="mr-6"
                  value={selectedValue}
                  onChange={handleSelectChange}
                >


                  <option   name="" value="All">
                    All status
                  </option>
                  <option name="Passed" value={true}>
                    Passed
                  </option>
                  <option name="Failed" value={false}>
                    Failed
                  </option>
                </select>
              </div>

            </div>

            <div className="mt-10 ml-1 lg:ml-10 grid grid-cols-3 bg-line pb-1">
              <div className="pt-2 w-3/12 ml-4 lg:ml-20">
                <h1 className="texts text-xs  lg:text-base">NAME</h1>
              </div>

              <div className="pt-2 w-6/12 ml-6 lg:ml-32 ">
                <h1 className="texts text-xs lg:text-base">SCORE </h1>
              </div>

              <div className="pt-2 w-3/12  ml-5 lg:ml-0">
                <h1 className="texts text-xs lg:text-base">RESULT</h1>
              </div>
            </div>

            {talentData?.map((talents) => (
              <div className="mt-4 ml-1 lg:ml-10 grid grid-cols-3  ">
                <div className=" w-3/12 ml-4 lg:ml-20 mb-4 pt-6 ">
                  <h1 className=" text-sm lg:text-xl font-medium">
                    {talents.talent.firstName}
                  </h1>
                  <p className="invisible lg:visible">{talents.talent.email}</p>
                </div>

                <div className=" w-6/12 ml-6 lg:ml-32  pt-8">
                  <h1 className="text-sm lg:text-base">{talents.score}</h1>
                </div>

                <div
                  className={`${
                    talents.status === "Failed"
                      ? "test-failed-1 lg:test-failed"
                      : talents.status === "Pending" || talents.status === null
                      ? "test-pending-1 lg:test-pending"
                      : "test-passed-1 lg:test-passed"
                  }   w-3/12 ml-5 lg:ml-0 text-xs mt-6 pt-1`}
                >
                  <h1 className="text-center pt-1">{talents.status}</h1>
                </div>
              </div>
            ))}
            <ReactPaginate
              className="flex place-content-center mx-auto my-8"
              breakLabel="..."
              nextLabel={<FiChevronRight className="text-3xl " />}
              onPageChange={handlePageChange}
              pageRangeDisplayed={5}
              pageCount={totalPages}

              previousLabel={<FiChevronLeft className="text-3xl " />}
              previousClassName={""}
              previousLinkClassName="text-xs text-blue"
              nextClassName=""
              disabledClassName="border-gray-400"
              disabledLinkClassName="text-gray-400"
              nextLinkClassName="text-xs text-blue"
              containerClassName="flex"
              pageClassName="subtext mx-2 text-xl"
              activeClassName="next-active "
              activeLinkClassName="text-blue"
            />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Speech_test_results;
