import React, { useState } from "react";
import Layout from "../components/user_layout";
import image from "../assets/images/create-talent.svg";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import eye from "../assets/images/eye.svg";
import hidden from "../assets/images/eye-off.svg";
import { FaSpinner } from 'react-icons/fa';
import { CURRENT_TEST_ROUTE, CURRENT_TEST_TOKEN } from "../utils/constants";

const Login = () => {
  const [passwordType, setPasswordType] = useState("password");

  const schema = yup.object().shape({
    email: yup.string().email("Not a valid email").required("Required"),
    password: yup.string().min(8).required(),
  });

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const talentSigninURL = `${process.env.REACT_APP_API_URL}/v1/auth/talent-signin`;

    const [isLoading, setIsLoading] = useState(false);

  const onSubmitHandler = (data) => {
    setIsLoading(true);
    axios
      .post(talentSigninURL, data)

      .then(function (response) {
        setIsLoading(false);
        const res = response.data.data;

        localStorage.setItem("userToken", res.token);
        localStorage.setItem(
          "fullname",
          res.user.firstName + " " + res.user.lastName
        );
        localStorage.setItem("email", res.user.email);

        navigate(`${localStorage.getItem(CURRENT_TEST_ROUTE)}?token=${localStorage.getItem(CURRENT_TEST_TOKEN)}`)
      })

      .catch(function (error) {
        setIsLoading(false);
        setError("apiError", { message: error });
      });
  };

  const togglePasswordHandler = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  return (
    <div className="allAuth">
      <Layout>
        <div className="auth">
          <div className="grid lg:grid-cols-2">
            <div className="self-center justify-self-center lg:justify-start hidden lg:block lg:mr-5 mt-20">
              <img src={image} alt="" />
            </div>

            <div className="mt-10 lg:mt-20">
              <form
                onSubmit={handleSubmit(onSubmitHandler)}
                className="grid gap-3"
              >
                <h1 className="pb-6 header text-2xl lg:text-[32px] font-bold font-poppins leading-10">
                  Welcome back !
                </h1>

                <div>
                  <label htmlFor="">Email Address</label> <br />
                  <input
                    type="email"
                    name="email"
                    required
                    className="form-control w-full font-poppins"
                    placeholder="name@email.com"
                    {...register("email", { required: true })}
                  />
                  <p className="text-red-600 font-bold">
                    {errors.apiError && "please insert your correct email"}{" "}
                  </p>
                </div>
                <div>
                  <label htmlFor="">Password</label> <br />
                  <div className="relative">
                    {passwordType === "password" ? (
                      <img
                        className="absolute right-4 translate-y-1/3"
                        src={hidden}
                        alt=""
                        onClick={togglePasswordHandler}
                      />
                    ) : (
                      <img
                        className="absolute right-4 translate-y-1/3"
                        src={eye}
                        alt=""
                        onClick={togglePasswordHandler}
                      />
                    )}
                    <input
                      type={passwordType}
                      name="password"
                      required
                      className="form-control w-full font-poppins"
                      placeholder="Password"
                      {...register("password", { required: true })}
                    />
                  </div>
                  <p className="text-red-600 font-bold ">
                    {errors.apiError && "please insert your correct password"}
                  </p>
                </div>

                <button
                id="my-button"
                className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex  place-content-center"
              >
                {isLoading && (
                  <FaSpinner
                    isLoading={isLoading}
                    className={`mt-1 mr-2 spinner-1  `}
                  />
                )}
                {isLoading ? <span>Signing in</span> : <span> Sign In </span>}
              </button>



                <div className="self-center justify-self-center mt-5 ">
                  <Link
                    to="/forget_password"
                    className="text-center blue lg:text-[14px] font-medium font-poppins "
                  >
                    Forgot Password?
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Login;
