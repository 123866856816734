import React from "react";
import Layout from "../components/user_layout";
import success from "../assets/images/success.svg";
import { Link } from "react-router-dom";

const Forget_password_otp = () => {
  return (
    <div className="allAuth">
      <Layout>
        <div className="font-poppins">
          <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
            <div className="'mx-auto bg-center">
              <img src={success} alt="" className="object-center" />
            </div>
            <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
              {" "}
              Password reset
            </h1>
          </div>
          <div className=" mx-auto  w-4/12 pb-4">
            <p className=" text-center subtext text-[16px] ">
              Your password has been successfully reset.
            </p>
            <p className=" text-center subtext text-[16px] ">
              {" "}
              Click below to login.
            </p>
          </div>

          <div className="btn mt-4 font-medium font-poppins form-control  mx-auto w-4/12 flex  place-content-center ">
            <Link className=" font-semibold" to="/login">
              Continue
            </Link>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Forget_password_otp;
