import React from "react";
import Layout from "../components/user_layout";
import { Link } from "react-router-dom";
import Submit from "../assets/images/submit.svg";

const Submit_voice_test = () => {
  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
          <div className="mx-auto bg-center">
            <img src={Submit} alt="" className="object-center" />
          </div>
          <h1 className="text-2xl lg:text-[32px] header font-semibold mt-10">
            {" "}
            Speech test submitted successfully
          </h1>

          <p className="text-center texts mt-4 text-2xl font-medium">
            Look out for your result in your email and also check the promotions
            or important folders of
            <br /> email if it doesn't show up in your primary inbox.
          </p>

          <Link
            to="/"
            className=" flex btn mx-auto w-2/12 place-content-center mt-10 text-20[px]"
          >
            Continue
          </Link>
        </div>
      </Layout>
    </div>
  );
};

export default Submit_voice_test;
