import React, { useEffect, useState } from "react";
import Admin_layout from "../../components/layout";
import move from "../../assets/icons/move.svg";
import copy from "../../assets/icons/copy.svg";
import trash from "../../assets/icons/trash.svg";
import plus from "../../assets/icons/plus.svg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Admin_header from "../../components/adminheader";
import { handleMove } from "../../components/handleMove";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const roles = localStorage.getItem("roles");

const apiRoles = roles
  ? roles
      .replace(/ /g, "_")
      .replace(/[(]/g, "_")
      .replace(/[)]/g, "")
      .replace(/\//g, "_")
      .toLocaleLowerCase()
  : "";

const inititalState = {
  question: "",
  roles: [roles ? apiRoles : []],
};

const Rolespecific_add_question = () => {
  const [questions, setQuestions] = useState(inititalState);
  const [getQuestions, gettQuestions] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalPage, setTotalPage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const api =
        `${process.env.REACT_APP_API_URL}/v1/tests/role-specific-questions-admin?skip=${skip}&limit=&role=` +
        apiRoles;
      await axios.get(api, { headers }).then((res) => {
        const resQuestions = res.data.data.docs;
        const resPage = res.data.data.totalPages;
        setTotalPage(resPage);
        gettQuestions([...getQuestions, ...resQuestions]);
      });
    })().catch(function (error) {
      navigate("/zedi/operations/mtaas-talent/admin/login");
    });
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");

    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/role-specific-questions-admin`;
      await axios.post(api, questions, { headers }).then((res) => {
        toast.update(id, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 3000,
        });
        window.location.reload();
      });
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestions((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDelete = (e) => {
    const toastid = toast.loading("Loading...");
    const id = e.currentTarget.id;
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/v1/tests/role-specific-questions/${id}`,
          { headers }
        )
        .then((res) => {
          toast.update(toastid, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            toastId: 1,
            autoClose: 3000,
          });
          window.location.reload();
        });
    } catch (error) {
      toast.update(toastid, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleCopy = (e) => {
    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-admin`;
      axios.post(api, { headers }).then((res) => {
        toast.update({
          render: res.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 3000,
        });
        window.location.reload();
      });
    } catch (error) {
      toast.update({
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const scrollTo = (ref) => {
    if (ref /* + other conditions */) {
      ref.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const fetchMoreData = () => {
    if (skip === totalPage) {
      return;
    }
    setSkip(skip + 1);
  };

  return (
    <div className="test allAuth none font-poppins">
      <Admin_layout>
        <div className="px-14">
          <Admin_header title={"Role Specific Test" + " - " + roles} />
          <ToastContainer />

          <InfiniteScroll
            dataLength={getQuestions.length}
            next={fetchMoreData}
            hasMore={true}
          >
            {getQuestions.map((x, index) => (
              <li
                className="flex my-6 gap-x-4 column"
                draggable="true"
                id={x.id}
              >
                <div className="border rounded-2xl px-8 w-full py-6">
                  <div className="pb-4 hidden">
                    <img
                      src={move}
                      alt=""
                      className="cursor-move"
                      onClick={handleMove}
                    />
                    <div className="flex gap-x-4 ml-auto">
                      <button type="submit" onClick={handleCopy}>
                        <img src={copy} alt="" />
                      </button>
                      <button type="reset" onClick={handleDelete} id={x.id}>
                        <img src={trash} alt="" />
                      </button>
                    </div>
                  </div>
                  {x.question}
                </div>
              </li>
            ))}
          </InfiniteScroll>

          <form onSubmit={handleSubmit} className="mt-6 mb-20">
            <div className="grid py-4" ref={scrollTo}>
              <div className="flex gap-x-4">
                <div className="mt-10"></div>
                <div className="border rounded-2xl px-8 w-full py-6">
                  <div className="flex pb-4">
                    <button>
                      <img src={move} alt="" />
                    </button>
                    <div className="flex gap-x-4 ml-auto">
                      <button>
                        <img src={copy} alt="" />
                      </button>
                      <button type="reset">
                        <img src={trash} alt="" />
                      </button>
                    </div>
                  </div>
                  <p>Question</p>
                  <input
                    type="text"
                    className="mt-3 mb-6 w-full border-[1.5px] border-[#612FF1]"
                    placeholder="Enter your question"
                    name="question"
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>


            <button
              className="btn-outline flex gap-x-2 my-8 opacity-0"
              onClick={""}
              type="reset"
            >
              <img src={plus} alt="" className="self-center" />
              <div className="self-center font-medium">Add question</div>
            </button>

            <button className="btn w-full mb-auto self-end" type="submit">
              Save
            </button>
          </form>
        </div>
      </Admin_layout>
    </div>
  );
};

export default Rolespecific_add_question;
