import React, { useEffect, useState } from "react";
import Layout from "../../components/layout";
import move from "../../assets/icons/move.svg";
import copy from "../../assets/icons/copy.svg";
import trash from "../../assets/icons/trash.svg";
import plus from "../../assets/icons/plus.svg";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Admin_header from "../../components/adminheader";
import { handleMove } from "../../components/handleMove";
import { useNavigate } from "react-router-dom";

const initialState = {
  question: "",
};

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Written_add_question = () => {
  const [questions, setQuestions] = useState([initialState]);
  const [getQuestions, gettQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-admin?skip=0&limit=1000`;
      await axios
        .get(api, { headers })
        .then((res) => {
          const resQuestions = res.data.data.docs;
          gettQuestions(resQuestions);
        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    })();
     //eslint-disable-next-line
  }, []);

  const AddQuestion = (e) => {
    e.preventDefault();
    if (questions.length <= 1) {
      setQuestions([
        ...questions,
        {
          question: "",
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");

    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-admin`;
      await axios.post(api, questions, { headers }).then((res) => {
        toast.update(id, {
          render: res.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 3000,
        });
        window.location.reload();
      });
    } catch (error) {
      toast.update(id, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuestions((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDelete = (e) => {
    const toastid = toast.loading("Loading...");
    const id = e.currentTarget.id;
    try {
      axios
        .delete(
          `${process.env.REACT_APP_API_URL}/v1/tests/written-questions/${id}`,
          { headers }
        )
        .then((res) => {
          toast.update(toastid, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            toastId: 1,
            autoClose: 3000,
          });
          window.location.reload();
        });
    } catch (error) {
      toast.update(toastid, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleCopy = (e) => {
    const id = e.currentTarget.id;
    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-admin`;
      axios.post(api, { headers }).then((res) => {
        toast.update({
          render: res.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 3000,
        });
        window.location.reload();
      });
    } catch (error) {
      toast.update({
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const scrollTo = (ref) => {
    if (ref /* + other conditions */) {
      ref.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="test allAuth none font-poppins">
      <Layout>
        <div className="px-14 written">
          <Admin_header title="Written Test" />
          <ToastContainer />
          <div className="" id="columns">
            {getQuestions.map((x, index) => (
              <li
                className="flex my-6 gap-x-4 column"
                draggable="true"
                id={x.id}
                key={x.id}
              >
                <div className="border rounded-2xl px-8 w-full py-6">
                  <div className="pb-4 hidden">
                    <img
                      src={move}
                      alt=""
                      className="cursor-move"
                      onClick={handleMove}
                    />
                    <div className="flex gap-x-4 ml-auto">
                      <button type="submit" onClick={handleCopy}>
                        <img src={copy} alt="" />
                      </button>
                      <button type="reset" onClick={handleDelete} id={x.id}>
                        <img src={trash} alt="" />
                      </button>
                    </div>
                  </div>
                  {x.question}
                </div>
              </li>
            ))}
          </div>
          <form onSubmit={handleSubmit} className="mt-6 mb-20">
            <div className="testBox grid py-4" ref={scrollTo}>
              <div className="flex gap-x-4">
                <div className="mt-10"></div>
                <button
                  type="reset"
                  className="border rounded-2xl px-8 w-full py-6"
                >
                  <div className="testInnerbox">
                    <div className="flex pb-4">
                      <button>
                        <img src={move} alt="" />
                      </button>
                      <div className="flex gap-x-4 ml-auto">
                        <button>
                          <img src={copy} alt="" />
                        </button>
                        <button type="reset">
                          <img src={trash} alt="" />
                        </button>
                      </div>
                    </div>
                    <p className="text-left">Question</p>
                  </div>

                  <input
                    type="text"
                    className="mt-3 mb-6 w-full border-[1.5px] border-[#612FF1]"
                    placeholder="Enter your question"
                    name="question"
                    value={questions.question}
                    onChange={handleChange}
                  />
                </button>
              </div>
            </div>

            <button
              className="btn-outline flex gap-x-2 my-8 opacity-0"
              onClick={AddQuestion}
              type="reset"
            >
              <img src={plus} alt="" className="self-center" />
              <div className="self-center font-medium">Add question</div>
            </button>

            <button className="btn w-full mb-auto self-end" type="submit">
              Save
            </button>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Written_add_question;
