import React, { useState, useEffect } from "react";
import plus from "../../assets/icons/plus.svg";
import move from "../../assets/icons/move.svg";
import copy from "../../assets/icons/copy.svg";
import trash from "../../assets/icons/trash.svg";
import pick from "../../assets/icons/Answer-picker.svg";
import confirm from "../../assets/icons/Answer-marker.svg";
import cancel from "../../assets/icons/Cancel.svg";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Admin_layout from "../../components/layout";
import Admin_header from "../../components/adminheader";
import { handleMove } from "../../components/handleMove";
import { useNavigate } from "react-router-dom";

const initialState = {
  question: "",
  options: [],
  answer: "",
};

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Basicfit_add_questions = () => {
  const [options, setOptions] = useState({ options: [] });
  localStorage.setItem("basicfitOptions", JSON.stringify(options.options));
  const basicfitOptions = JSON.parse(localStorage.getItem("basicfitOptions"));

  const [state, setState] = useState(initialState);
  state.options = basicfitOptions;
  state.answer = +state.answer;

  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/mcqs-admin?skip=0&limit=1000`;
      await axios.get(api, { headers }).then((res) => {
        if (res.status === "error" && res.message === "unauthorized") {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        }
        const resQuestions = res.data.data.docs;
        setQuestions(resQuestions);
      });
    })();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = toast.loading("Loading...");

    try {
      await axios
        .post(`${process.env.REACT_APP_API_URL}/v1/tests/mcqs`, state, {
          headers,
        })
        .then((res) => {
          toast.update(id, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            toastId: 1,
            autoClose: 3000,
          });
        });
      window.location.reload();
    } catch (err) {
      toast.update(id, {
        render: err.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: e.target.value }));
  };

  const handleOptionChange = (e) => {
    const { value } = e.target;
    const idx = +e.target.dataset.idx;

    const handleOptions = options.options.map((x, index) => {
      if (index === idx) {
        return value;
      }
      return x;
    });
    setOptions({ options: handleOptions });
  };

  const AddOption = (e) => {

    e.preventDefault();
    options.options.push("");
    if (options.options.length <= 5) {
      setOptions({ options: [...options.options] });
    } else {
      options.options.length = 5;
    }
  };

  const DeleteOption = () => {
    options.options.pop("");
    setOptions({ options: [...options.options] });
  };

  const handleDelete = (e) => {
    const toastid = toast.loading("Loading...");
    const id = e.currentTarget.id;
    try {
      axios
        .delete(`${process.env.REACT_APP_API_URL}/v1/tests/mcqs/${id}`, {
          headers,
        })
        .then((res) => {
          toast.update(toastid, {
            render: res.data.message,
            type: "success",
            isLoading: false,
            toastId: 1,
            autoClose: 3000,
          });
          window.location.reload();
        });
    } catch (error) {
      toast.update(toastid, {
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const handleCopy = (e) => {
    const id = e.currentTarget.id;
    try {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/written-questions-admin`;
      axios.post(api, { headers }).then((res) => {
        toast.update({
          render: res.data.message,
          type: "success",
          isLoading: false,
          toastId: 1,
          autoClose: 3000,
        });
        window.location.reload();
      });
    } catch (error) {
      toast.update({
        render: error.response.data.message,
        type: "error",
        isLoading: false,
        toastId: 2,
        autoClose: 3000,
      });
    }
  };

  const scrollTo = (ref) => {
    if (ref /* + other conditions */) {
      ref.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div className="test allAuth none font-poppins">
      <Admin_layout>
        <div className="px-14">
          <Admin_header title="Basic Fit Test" />
          <ToastContainer />
          <div className="" id="columns">
            {questions.map((x, index) => (
              <li
                className="flex my-6 gap-x-4 column"
                draggable="true"
                id={x.id}
              >
                <div className="border rounded-2xl px-8 w-full py-6">
                  <div className="pb-4 hidden">
                    <img
                      src={move}
                      alt=""
                      className="cursor-move"
                      onClick={handleMove}
                    />
                    <div className="flex gap-x-4 ml-auto">
                      <button type="submit" onClick={handleCopy}>
                        <img src={copy} alt="" />
                      </button>
                      <button type="reset" onClick={handleDelete} id={x.id}>
                        <img src={trash} alt="" />
                      </button>
                    </div>
                  </div>
                  <p> {x.question} </p>
                  <br />
                  <li>
                    {x.answer == 0 ? (
                      <>
                        <span className="-ml-2 mr-3 px-2.5 py-1 rounded bg-green-600 text-white">
                          A
                        </span>{" "}
                        <span className="text-green-600">{x.options[0]}</span>
                      </>
                    ) : (
                      <>
                        <span className="mr-6">A</span> {x.options[0]}{" "}
                      </>
                    )}
                  </li>
                  <li className="py-2">
                    {x.answer == 1 ? (
                      <>
                        <span className="-ml-2 mr-3 px-2.5 py-1 rounded bg-green-600 text-white">
                          B
                        </span>{" "}
                        <span className="text-green-600">{x.options[1]}</span>
                      </>
                    ) : (
                      <>
                        <span className="mr-6">B</span> {x.options[1]}{" "}
                      </>
                    )}
                  </li>
                  <li>
                    {x.answer == 2 ? (
                      <>
                        <span className="-ml-2 mr-3 px-2.5 py-1 rounded bg-green-600 text-white">
                          C
                        </span>{" "}
                        <span className="text-green-600">{x.options[2]}</span>
                      </>
                    ) : (
                      <>
                        <span className="mr-6">C</span> {x.options[2]}{" "}
                      </>
                    )}
                  </li>
                  <li className="py-2">
                    {x.answer == 3 ? (
                      <>
                        <span className="-ml-2 mr-3 px-2.5 py-1 rounded bg-green-600 text-white">
                          D
                        </span>{" "}
                        <span className="text-green-600">{x.options[3]}</span>
                      </>
                    ) : (
                      <>
                        <span className="mr-6">D</span> {x.options[3]}{" "}
                      </>
                    )}
                  </li>
                  <li className="py-2">
                    {x.options.length !== 5 && x.answer !== 4 ? (
                      ""
                    ) : x.answer == 4 ? (
                      <>
                        <span className="-ml-2 mr-3 px-2.5 py-1 rounded bg-green-600 text-white">
                          E
                        </span>{" "}
                        <span className="text-green-600">{x.options[4]}</span>
                      </>
                    ) : (
                      <>
                        <span className="mr-6">E</span> {x.options[4]}{" "}
                      </>
                    )}
                  </li>
                </div>
              </li>
            ))}
          </div>

          <form className="mt-6 mb-20 w-full h-100" onSubmit={handleSubmit}>
            <div className="border rounded-2xl px-8 w-full py-6" ref={scrollTo}>
              <div className="flex pb-4">
                <button>
                  <img src={move} alt="" />
                </button>
                <div className="flex gap-x-4 ml-auto">
                  <button>
                    <img src={copy} alt="" />
                  </button>
                  <button>
                    <img src={trash} alt="" />
                  </button>
                </div>
              </div>
              <p>Question</p>
              <input
                type="text"
                className="mt-3 mb-6 w-full border-[1.5px] border-[#612FF1]"
                placeholder="Enter your question"
                name="question"
                value={state.question}
                onChange={handleChange}
              />

              {options.options.length !== 0
                ? options.options.map((x, index) => (
                    <div
                      className="flex gap-x-4 mb-6 column"
                      draggable="true"
                      key={x.id}
                      onClick={handleMove}
                    >
                      <img src={move} alt="" className="cursor-move" />
                      <div className="self-center">
                        {index == 0
                          ? "A"
                          : index == 1
                          ? "B"
                          : index == 2
                          ? "C"
                          : index == 3
                          ? "D"
                          : index == 4
                          ? "E"
                          : null}
                      </div>
                      <input
                        type="text"
                        className="w-full border border-gray-300 rounded"
                        placeholder="Option"
                        name="options"
                        value={x.option}
                        data-idx={index}
                        onChange={handleOptionChange}
                      />

                      <a className="cursor-pointer">
                        <input
                          type="radio"
                          name="answer"
                          value={index}
                          id=""
                          onChange={handleChange}
                          className="mt-4 ml-4 scale-[3.0] opacity-0 cursor-pointer"
                        />
                        {state.answer == index ? (
                          <img
                            src={confirm}
                            alt=""
                            className="-mt-8 pointer-events-none"
                          />
                        ) : (
                          <img
                            src={pick}
                            alt=""
                            className="-mt-8 pointer-events-none"
                          />
                        )}
                      </a>
                      <button type="button" onClick={DeleteOption}>
                        <img src={cancel} alt="" />
                      </button>
                    </div>
                  ))
                : ""}

              <button
                className="bg-[#F0EBFF] text-[#612FF1] px-4 py-2 rounded-lg flex gap-x-2"
                onClick={AddOption}
                type="button"
              >
                <img src={plus} alt="" className="self-center" />
                <div className="self-center font-medium">Add option</div>
              </button>
            </div>

            <button
              className="btn-outline flex gap-x-2 my-8 opacity-0"
              type="reset"
            >
              <img src={plus} alt="" className="self-center" />
              <div className="self-center font-medium">Add question</div>
            </button>

            <button className="btn w-full mb-auto self-end" type="submit">
              Save
            </button>
          </form>
        </div>
      </Admin_layout>
    </div>
  );
};

export default Basicfit_add_questions;
