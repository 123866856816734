import React from "react";
import Layout from "../components/user_layout";
import notFound from "../assets/images/not-found.svg";
import { Link } from "react-router-dom";

const Not_found = () => {
  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
          <div className="'mx-auto bg-center">
            <img src={notFound} alt="" className="object-center" />
          </div>
          <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
            {" "}
            Page not found
          </h1>

          <p className="text-center subtext mt-6 text-base">
            We are sorry, the page you requested cannot be found.
          </p>
          <p className="text-center subtext text-base">
            Please go back to the homepage
          </p>

          <Link
            to="/"
            className=" flex btn mx-auto w-2/12 place-content-center mt-10 text-20[px]"
          >
            Go back
          </Link>
        </div>
      </Layout>
    </div>
  );
};

export default Not_found;
