import React, { useState, useContext } from "react";
import Layout from "../components/user_layout";
import password from "../assets/images/password.svg";
import { Link } from "react-router-dom";
import arrowleft from "../assets/images/arrow-left.svg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/authcontext/auth_context";
import eye from "../assets/images/eye.svg";
import hidden from "../assets/images/eye-off.svg";
import { FaSpinner } from "react-icons/fa";

const schema = yup.object().shape({
  password: yup.string().min(8).required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null]),
});

const Set_new_password = () => {
  const [passwordType, setPasswordType] = useState({
    password: true,
    confirmPassword: true,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const auth_context = useContext(AuthContext);
  const { userToken } = auth_context;
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const setNewPasswordURL = `${process.env.REACT_APP_API_URL}/v1/auth/talent-reset-password`;

  const onSubmitHandler = (data) => {
    setIsLoading(true);
    delete data.confirmPassword;
    data.token = userToken;


    axios
      .patch(setNewPasswordURL, data)

      .then((response) => {
        navigate("/password_reset_success");
      })
      .catch((error) => {
        console.error("There was an error !", error.message);
      });
  };

  const togglePasswordHandler = (idx) => {
    if (idx === 1) {
      setPasswordType({
        ...passwordType,
        password: !passwordType.password,
      });
    } else {
      setPasswordType({
        ...passwordType,
        confirmPassword: !passwordType.confirmPassword,
      });
    }
  };

  return (
    <div className="password">
      <Layout>
        <div className="font-poppins">
          <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
            <div className="'mx-auto bg-center">
              <img src={password} alt="" className="object-center" />
            </div>

            <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
              {" "}
              Set new password{" "}
            </h1>
          </div>
          <div className=" mx-auto  w-4/12 pb-4">
            {" "}
            <p className=" text-center subtext ">
              Please enter a new password.
            </p>{" "}
          </div>
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <div className=" mx-auto  w-4/12">
              <label htmlFor="">Password</label>
              <div className="relative">
                {passwordType.password ? (
                  <img
                    className="absolute right-4 translate-y-1/2"
                    src={hidden}
                    alt=""
                    onClick={() => togglePasswordHandler(1)}
                  />
                ) : (
                  <img
                    className="absolute right-4 translate-y-1/2 "
                    src={eye}
                    alt=""
                    onClick={() => togglePasswordHandler(1)}
                  />
                )}
                <input
                  type={passwordType.password ? "password" : "text"}
                  name="password"
                  required
                  className="form-control w-full mt-2 font-poppins"
                  placeholder="Enter new password"
                  {...register("password", { required: true })}
                />
              </div>
              <p className="subtext font-normal text-[12px] mt-2 mb-6 ">
                must be at least 8 characters
              </p>
              <p className="text-red-600 font-bold ">
                {errors.password && "Password should at least be 8 characters"}
              </p>
            </div>
            <div className=" mx-auto  w-4/12">
              <label htmlFor="">Confirm new password</label>
              <div className="relative">
                {passwordType.confirmPassword ? (
                  <img
                    className="absolute right-4 translate-y-1/2"
                    src={hidden}
                    alt=""
                    onClick={() => togglePasswordHandler(2)}
                  />
                ) : (
                  <img
                    className="absolute right-4 translate-y-1/2 "
                    src={eye}
                    alt=""
                    onClick={() => togglePasswordHandler(2)}
                  />
                )}
                <input
                  type={passwordType.confirmPassword ? "password" : "text"}
                  name="confirmPassword"
                  required
                  className="form-control w-full mt-2 font-poppins"
                  placeholder="Confirm new password"
                  {...register("confirmPassword", { required: true })}
                />
              </div>
              <p className="text-red-600 font-bold ">
                {errors.password && "Password should match"}
              </p>
            </div>
            <div className="mx-auto w-4/12">
            <button
                id="my-button"
                onClick={onSubmitHandler}
                className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex  place-content-center"
              >
                {isLoading && (
                  <FaSpinner
                    isLoading={isLoading}
                    className={`mt-1 mr-2 spinner-1  `}
                  />
                )}
                {isLoading ? <span>Loading </span> : <span>Reset</span>}
              </button>
            </div>
          </form>

          <Link
            to="/login"
            className=" flex mx-auto place-content-center mt-4 subtext lg:text-[12px]"
          >
            <img src={arrowleft} alt="" /> Back to log in
          </Link>
        </div>
      </Layout>
    </div>
  );
};

export default Set_new_password;
