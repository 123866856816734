import React, { useEffect, useState } from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { HiArrowRight } from "react-icons/hi";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const initialState = {
  submission: [],
};

const Basic_fit_Test = () => {
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState(initialState);
  const [token, setToken] = useState(() => localStorage.userToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const url = window.location.origin;
  const navigate = useNavigate();

  if (window.location.hash !== "#loaded") {
    window.location.hash = "#loaded";
    window.location.reload();
  }

  window.onbeforeunload = function () {
    return "Reloading the page will automatically submit your test!";
  };

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/mcqs-talent`;
      await axios
        .get(api, { headers })
        .then((result) => {
          const questions = result.data.data.docs;

          setTimeout(setQuestions(questions), 5000);
        })
        .catch(function (error) {
          navigate("/login");
        });
    };

    getData();
    //eslint-disable-next-line
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await axios.patch(
      `${process.env.REACT_APP_API_URL}/v1/tests/mcqs-submission`,
      { ...answers, verbalTestUrl: `${url}/speech_test_guidelines` },
      { headers }
    );
    if (res) {
      navigate("/status/success");
    } else {
      navigate("/status/error");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let prevAnswered = false;
    answers.submission.map((ans) => {
      if (ans.id === name) {
        ans.value = value;
        prevAnswered = true;
      }

      return ans;
    });

    if (prevAnswered === false) {
      setAnswers({
        submission: [...answers.submission, { id: name, choice: +value }],
      });
    }
  };

  document.addEventListener("visibilitychange", (e) => {
      if (document.visibilityState === "visible") {
        let submitBtn = document.getElementById("submitBtn");
        submitBtn.click();
      } else {
      }
  });

  return (
    <div className="hiddenPages">
      <Layout>
        <div className="test font-poppins">
          <Banner title="Basic fit test" />

          <form
            action=""
            className="w-8/12 mx-auto py-14"
            id="basicfittest"
            onSubmit={handleSubmit}
          >
            {questions.length !== 0 ? (
              questions.map((x, index) => (
                <div className="py-4" key={x.id}>
                  <h1 className="font-medium text-xl">
                    {index + 1 + ". "}
                    {x.question}
                  </h1>
                  {x.options.map((e, idx) => (
                    <div className="px-6">
                      <input
                        key={e}
                        type="radio"
                        name={x.id}
                        value={idx}
                        onChange={handleChange}
                      />
                      <span className="px-2 text-lg">{e}</span>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <div className="spinner mx-auto mt-20"></div>
            )}

            {questions.length !== 0 ? (
              <button
                type="submit"
                id="submitBtn"
                className="btn float-right w-full flex justify-center mt-10"
              >
                <span>Submit</span>
                <HiArrowRight className="self-center ml-2" />
              </button>
            ) : (
              ""
            )}
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Basic_fit_Test;
