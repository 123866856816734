const Auth_context_reducer = (state, action) => {
  switch (action.type) {
    case "SET_EMAIL":
      return { ...state, userEmail: action.payload };
    case "SET_TOKEN":
      return { ...state, userToken: action.payload };
    default:
      break;
  }
};

export default Auth_context_reducer;
