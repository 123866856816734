import React from "react";
import Layout from "../components/user_layout";
import confirmation from "../assets/icons/confirmation.svg";

const Email_confirmation = () => {
  return (
    <div className="confirmation font-poppins">
      <Layout>
        <div className="mx-auto text-center w-screen content-center px-8 pt-14 md:px-0 md:w-9/12">
          <img src={confirmation} alt="" className="mx-auto w-3/12" />
          <h1 className="font-bold text-[32px] pt-6 pb-3">
            Email address confirmed.
          </h1>
          <p className="subtext text-[16px]">
          Your email address is now confirmed, please check your email for next steps.
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default Email_confirmation;
