import React from "react";
import logo from "../assets/logo/logo.svg";
import { FiUser, FiMail } from "react-icons/fi";

const Navbar = () => {
  let fullname = localStorage.getItem("fullname");
  let email = localStorage.getItem("email");

  return (
    <nav className="lg:grid lg:grid-cols-2 py-7 font-poppins">
      <div className="flex justify-center lg:justify-start">
        <img src={logo} className="" alt="" />
        <p className='header self-center justify-center text-sm lg:text-xl font-poppins'>Zedi Talent Test</p>
      </div>

      <div className="flex self-center justify-end gap-x-3 loggedin-icons">
        <p className="flex">
          <FiUser
            className="text-[#4214C8] user self-center mr-1 font-poppins"
            size={20}
          />{" "}
          {fullname}{" "}
        </p>
        <p className="flex">
          <FiMail
            className="text-[#4214C8] email self-center mr-1 font-poppins"
            size={20}
          />{" "}
          {email}{" "}
        </p>
      </div>
      <div className="justify-end admin hidden">
        <button className="purple border border-[#612FF1] px-3 rounded-lg font-medium text-sm">
          Create Account
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
