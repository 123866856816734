import React, { useState, useEffect } from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { useNavigate } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";
import { CURRENT_TEST_ROUTE, CURRENT_TEST_TOKEN } from "../utils/constants";

const Speech_test_guidelines = () => {
  const [token, setToken] = useState(() => localStorage.userToken);
  const [searchParams] = useSearchParams();
  localStorage.setItem(CURRENT_TEST_ROUTE, "/speech_test_guidelines")
  localStorage.setItem(CURRENT_TEST_TOKEN, searchParams.get("token"))

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    //eslint-disable-next-line
  }, [token]);

  return (
    <div className="hiddenPages font-poppins">
      <Layout>
        <div className="test_guidelines ">
          <Banner title="Speech Test" />
          <div className="px-8 lg:px-32  mx-auto w-11/12 pb-20 pt-12 bg-[#F5F3FB] my-8">
            <h1 className="text-2xl font-semibold pb-6 base">
              General Instructions
            </h1>
            <ul className="list texts leading-10 text-xl mx-auto w-11/12">
              <li>Please take this test in a quiet environment </li>
              <li>
                Please take this test using the Google Chrome browser on your
                computer
              </li>
              <li>Make sure you have a stable internet connection </li>
              <li>Allow/Enable the microphone when asked </li>
              <li>
                You will be given 5 sentences to record with your speech. When
                the sentence appears, click on the <strong>"Start recording"</strong> button and
                speak and click on the <strong>"Stop recording"</strong> button when you are
                done.
              </li>
              <li>You need to get a score of at least 80% to pass this test, so speak as clearly as possible.</li>
              <li>Click the “Start recording” button, read out the text displayed and then click “STOP RECORDING”</li>
              <li>You will be notified if your microphone is good enough to take the test or if you should test your microphone again before you proceed to take your test.</li>
            </ul>
            <p className=" texts leading-10 text-xl mx-auto w-11/12">
              NB: You are not to open any other windows or refresh the page. If
              you do, the assessment will end immediately.
            </p>
            <p className=" texts leading-10 text-xl mx-auto w-11/12">
              When you are ready, agree to the rules by clicking the check box
              below and then hit the <strong>Test Mic</strong> button.
            </p>
          </div>

           <form className="grid lg:grid-cols-2 mx-20" onSubmit={() => navigate("/test_mic")}>
            <div className="flex pb-6 lg:pb-0">
              <input type="checkbox" name="" id="" required/>
              <p
                className="pl-2 self-center text-[18px]"
              >
                I agree to all the rules and regulations{" "}
              </p>
            </div>
            <div>
                <button className="btn float-right w-full lg:w-96 flex justify-center" type="submit" >
                  <span className="pt-1 self-center"> Test Mic</span>
                  <HiArrowRight className="self-center ml-2" />
                </button>
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Speech_test_guidelines;
