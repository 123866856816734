import React, { useState, useEffect } from "react";
import Layout from "../components/user_layout";
import image from "../assets/images/create-talent.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ROLES, LEVELS } from "../components/constants";
import { Country, State } from "country-state-city";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import eye from "../assets/images/eye.svg";
import hidden from "../assets/images/eye-off.svg";
import { FaSpinner } from "react-icons/fa";
import { ToastContainer } from 'react-toastify';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CURRENT_TEST_ROUTE } from "../utils/constants";

const Sign_up = () => {
  const schema = yup.object().shape({
    firstName: yup.string().min(2).required(),
    lastName: yup.string().min(2).required(),
    phone: yup.string().min(9).max(17).required(),
    email: yup.string().email().required(),
    country: yup.string().required(),
    state: yup.string().required(),
    password: yup.string().min(8).required(),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref("password"), null]),
    tandc: yup.bool().oneOf([true], "You must agree the terms and conditions"),
  });

  const [countries, setCountries] = useState(Country.getAllCountries());
  const [formCountry, setFormCountry] = useState("");
  const [countryStates, setCountryStates] = useState([]);
  const [passwordType, setPasswordType] = useState({

    password: true,
    confirmedPassword: true,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [res, setRes] = useState([]);

  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  const talentPostURL = `${process.env.REACT_APP_API_URL}/v1/auth/talent-signup`;

  const submitFormLogin = (data) => {
    const toastID = Math.random()
    data.country = formCountry;
    delete data.confirmPassword;
    delete data.tandc;

    // POST request using axios with error handling
    setIsLoading(true);

    axios
      .post(talentPostURL, data)

      .then((response) => {
    setIsLoading(false);
    localStorage.setItem(CURRENT_TEST_ROUTE, "/test_guidelines")
        setRes(res)

        navigate("/auth_confirmation");
      })
      .catch((error) => {

        toast.error(error.response.data.message, {toastId: toastID})
        setIsLoading(false);
      });
  };



  const changeHandler = (e) => {
    e.preventDefault();
    setFormCountry(Country.getCountryByCode(e.target.value).name);
    let stateArray = State.getStatesOfCountry(e.target.value);
    setCountryStates(stateArray);
  };

  useEffect(() => {});
  const togglePasswordHandler = (idx) => {
    if (idx === 1) {
      setPasswordType({
        ...passwordType,
        password: !passwordType.password,
      });
    } else {
      setPasswordType({
        ...passwordType,
        confirmedPassword: !passwordType.confirmedPassword,
      });
    }
  };

  return (
    <div className="allAuth">
       <ToastContainer />
      <Layout>
        <div className="auth">
          <div className="grid lg:grid-cols-2">
            <div className="self-center justify-self-center lg:justify-start hidden lg:block lg:mr-5 mt-12">
              <img src={image} alt="" />
            </div>

            <div className="mt-10 lg:mt-20 font-poppins">
              <form
                onSubmit={handleSubmit(submitFormLogin)}
                className="grid gap-3"
              >
                <h1 className="pb-6 header text-2xl lg:text-[32px] font-bold leading-10">
                  Create Account
                </h1>
                <div className="grid lg:grid-cols-2 gap-3">
                  <span className="">
                    <label htmlFor="">First name</label> <br />
                    <input
                      type="text"
                      name="firstName"
                      required
                      className="form-control w-full valid"
                      placeholder="First name"
                      {...register("firstName", { required: true })}
                    />
                    <p className="text-red-600 font-bold">
                      {errors.firstName &&
                        "Name should be at least 2 characters"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Last name</label> <br />
                    <input
                      type="text"
                      name="lastName"
                      required
                      className="form-control w-full valid"
                      placeholder="Last name"
                      {...register("lastName", { required: true })}
                    />
                    <p className="text-red-600 font-bold">
                      {errors.lastName &&
                        "Name should be at least 2 characters"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Phone Number</label> <br />
                    <Controller
                      control={control}
                      name="phone"
                      rules={{ required: true }}
                      render={({ field: { ref, ...field } }) => (
                        <PhoneInput
                          {...field}
                          inputExtraProps={{
                            ref,
                            required: {
                              ...register("phone", { required: true }),
                            },
                            autoFocus: true,
                          }}
                          country={"ng"}
                        />
                      )}
                    />
                    <p className="text-red-600 font-bold">
                      {errors.phone && "Invalid number"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Email Address</label> <br />
                    <input
                      type="email"
                      name="email"
                      required
                      className="form-control w-full"
                      placeholder="name@email.com"
                      {...register("email", { required: true })}
                    />
                    <p className="text-red-600 font-bold">
                      {errors.email && "please add a valid email address"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Select Country</label> <br />
                    <select
                      name="country"
                      {...register("country", { required: true })}
                      required
                      className="form-control w-full"
                      onChange={changeHandler}
                    >
                      <option>Select your country</option>
                      {countries &&
                        countries.map((country) => (
                          <option value={country.isoCode} key={country.isoCode}>
                            {country.name}
                          </option>
                        ))}
                    </select>
                    <p className="text-red-600 font-bold">
                      {errors.country && "Please select your country"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Select State</label> <br />
                    <select
                      name="state"
                      {...register("state", { required: true })}
                      required
                      className="form-control w-full"
                    >
                      <option value="">Select your State</option>
                      {countryStates &&
                        countryStates.map((item) => (
                          <option value={item.name} key={item.name}>
                            {item.name}
                          </option>
                        ))}
                    </select>
                    <p className="text-red-600 font-bold">
                      {errors.state && "Please select your Country and State"}
                    </p>
                  </span>

                  <span className="">
                    <label htmlFor="">Select Role</label> <br />
                    <select
                      name="role"
                      {...register("role", { required: true })}
                      required
                      className="form-control w-full "
                    >
                      <option value="">Select your Role</option>
                      {ROLES.map((role) => (
                        <option value={role.label} key={role.label}>
                          {role.title}
                        </option>
                      ))}
                    </select>
                  </span>

                  <span className="">
                    <label htmlFor="">Select Level</label> <br />
                    <select
                      name="level"
                      {...register("level", { required: true })}
                      required
                      className="form-control w-full"
                    >
                      <option value="">Select your Level</option>
                      {LEVELS.map((level) => (
                        <option
                          value={level.label.toLowerCase()}
                          key={level.label}
                        >
                          {level.title}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <div>
                  <label htmlFor="">Password</label>
                  <div className="  relative ">
                    {passwordType.password ? (
                      <img
                        className="absolute right-4 translate-y-1/3"
                        src={hidden}
                        alt=""
                        onClick={() => togglePasswordHandler(1)}
                      />
                    ) : (
                      <img
                        className="absolute  right-4 translate-y-1/3"
                        src={eye}
                        alt=""
                        onClick={() => togglePasswordHandler(1)}
                      />
                    )}
                    <input
                      type={passwordType.password ? "password" : "text"}
                      name="password"
                      required
                      className="form-control w-full"
                      placeholder="Password"
                      {...register("password", { required: true })}
                    />
                  </div>


                  <p className="text-red-600 font-bold ">
                    {errors.password &&
                      "Password should at least be 8 characters"}
                  </p>
                </div>

                <div className="font-poppins ">
                  <label htmlFor="">Re-enter Password</label>
                  <div className="relative">
                    {passwordType.confirmedPassword ? (
                      <img
                        className="absolute right-4 translate-y-1/3"
                        src={hidden}
                        alt=""
                        onClick={() => togglePasswordHandler(2)}
                      />
                    ) : (
                      <img
                        className="absolute right-4 translate-y-1/3"
                        src={eye}
                        alt=""
                        onClick={() => togglePasswordHandler(2)}
                      />
                    )}

                    <input
                      type={
                        passwordType.confirmedPassword ? "password" : "text"
                      }
                      name="confirmPassword"
                      required
                      className="form-control w-full"
                      placeholder="Password"
                      {...register("confirmPassword", { required: true })}
                    />
                  </div>

                  <p className="text-red-600 font-bold font-poppins">
                    {errors.confirmPassword && "Password should match"}
                  </p>
                </div>

                <div className="flex pt-4">
                  <input
                    type="checkbox"
                    name="tandc"
                    {...register("tandc", { required: true })}
                    id=""
                  />
                  <p className="pl-2 font-poppins">I agree to the</p>
                  <Link
                    to="/terms_conditions"
                    className="text-center blue lg:text-[14px] ml-2  font-poppins "
                  >
                   terms and conditions
                  </Link>
                </div>
                <p className="text-red-600 font-poppins pb-4">
                  {errors.tandc && "You must agree the terms and conditions"}
                </p>

                <button
                  id="signup"
                  className="btn flex place-content-center font-poppins text-[14px]"
                  onClick={submitFormLogin}
                >
                  {isLoading && (
                    <FaSpinner
                      isLoading={isLoading}
                      className={`mt-1  mr-2 spinner-1  `}
                    />
                  )}
                  {isLoading && schema ? (
                    <span>Creating</span>
                  )
                 : res.status ==="error"?
                  (
                    <span>Create account</span>
                  )

                  : (
                    <span>Create account</span>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default Sign_up;
