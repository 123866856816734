import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import basic from "../../assets/images/basic.svg";
import speech from "../../assets/images/speech.svg";
import written from "../../assets/images/written.svg";
import role from "../../assets/images/role.svg";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { TEST_ROLES } from "../../components/constants";
import axios from "axios";

export const headers = {
  Authorization: "Bearer " + localStorage.getItem("adminToken"),
};

const Test_dashboard = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const [token, setToken] = useState(() => localStorage.adminToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const handleChange = (e) => {
    const { value } = e.target;
    localStorage.setItem("roles", value);

    const dataTitle = document.getElementById("rolespecific");
    const title = dataTitle.dataset.title;
    window.location.href = `${title}`;
  };

  useEffect(() => {
    if (!token) {
      navigate("/zedi/operations/mtaas-talent/admin/login");
    }

    //eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/questions-overview`;
      await axios
        .get(api, { headers })
        .then((res) => {
          if (res.status === "error" && res.message === "unauthorized") {
            navigate("/zedi/operations/mtaas-talent/admin/login");
          }
          const data = res.data.data;
          setData(data);

        })
        .catch(function (error) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results?skip=0&limit=10`;
      await axios.get(api, { headers }).then((res) => {
        if (
          res.data.status === "error" &&
          res.data.message === "unauthorized"
        ) {
          navigate("/zedi/operations/mtaas-talent/admin/login");
        }
        const dataInfo = res.data.data.docs;
        setTalentData(dataInfo);
      });
    };
    getData();
    //eslint-disable-next-line
  }, [token]);

  return (
    <div className=" allAuth none font-poppins">
      <Layout>
        <div className=" light mt-2 bg-base ">
          <h1 className="text-2xl pl-10 pt-9 font-semibold texts">Test</h1>
          <div className=" grid grid-cols-1 lg:grid-cols-2 mx-8 gap-8 ">
            <div className=" max-w-none  p-6 bg-white border light mt-5 rounded-lg  ">
              <div className="flex p">
                <img className="pl-8 pt-8" src={basic} alt="" />
                <p className="pl-2 pt-10 text-base subtext">Basic fit test</p>
              </div>
              <h1 className="pl-10 py-4 base text-base lg:text-2xl font-medium">
                {`${data.totalBasicFitQuestions} Questions`}
              </h1>
              <hr />
              <div className="flex lg:mx-8 place-content-between">
                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/basicfit_add_questions"
                    )
                  }
                  className="btn-outline-1 mt-5 px-4 text-center text-xs lg:text-base font-medium"
                >
                  Add questions
                </button>

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/basicfit_test_results"
                    )
                  }
                  className="  mt-7 blue text-xs lg:text-base font-medium "
                >
                  View results
                </button>
              </div>
            </div>

            <div className="max-w-none p-6 bg-white border light mt-5 rounded-lg ">
              <div className="flex ">
                <img className="pl-8 pt-8" src={speech} alt="" />
                <p className="pl-2 pt-10 text-base subtext">Speech test</p>
              </div>
              <h1 className="pl-10 py-4 base text-base lg:text-2xl font-medium">
                {`${data.totalVerbalQuestions} Questions`}
              </h1>
              <hr className="" />

              <div className="flex lg:mx-8 place-content-between">
                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/speech_add_question"
                    )
                  }
                  className="btn-outline-1 mt-5 px-4 text-center  text-xs lg:text-base font-medium"
                >
                  Add questions
                </button>

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/speech_test_results"
                    )
                  }
                  className=" mt-7 blue text-xs lg:text-base font-medium"
                >
                  View results
                </button>
              </div>
            </div>

            <div className="max-w-none   p-6 bg-white border light rounded-lg ">
              <div className="flex ">
                <img className="pl-8 pt-8" src={written} alt="" />
                <p className="pl-2 pt-10 text-base subtext">Written test</p>
              </div>
              <h1 className="pl-10 py-4 base text-base lg:text-2xl font-medium">
                {`${data.totalWrittenQuestions} Questions`}
              </h1>

              <hr />
              <div className="flex lg:mx-8 place-content-between">
                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_add_questions"
                    )
                  }
                  className="btn-outline-1 mt-5 px-4 text-center text-xs lg:text-base font-medium "
                >
                  Add questions
                </button>

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/written_test_result"
                    )
                  }
                  className="mt-7 blue text-xs lg:text-base font-medium"
                >
                  View results
                </button>
              </div>
            </div>

            <div className="max-w-none   p-6 bg-white border light rounded-lg  ">
              <div className="flex ">
                <img className="pl-8 pt-8" src={role} alt="" />
                <p className="pl-2 pt-10 text-base subtext">
                  Role specific test
                </p>
              </div>
              <h1 className="pl-10 py-4 base text-base lg:text-2xl font-medium">
                {`${data.totalRoleSpecificQuestions} Questions`}
              </h1>
              <hr />

              <div className="flex lg:mx-8 place-content-between">
                <select
                  className="bod-1 blue mt-6 text-[8px] lg:text-base font-medium custom-select-1 lg:custom-select "
                  onChange={handleChange}
                >
                  <option className="text-xs lg:text-base">
                    Add Questions
                  </option>
                  {TEST_ROLES.map((role, index) => (
                    <option
                      data-title={role.path}
                      value={role.title}
                      id="rolespecific"
                    >
                      {role.title}
                      <button
                        key={index}
                      ></button>
                    </option>
                  ))}
                </select>

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/rolespecific_test_result"
                    )
                  }
                  className=" mt-7 blue text-xs lg:text-base font-medium "
                >
                  View results
                </button>
              </div>
            </div>
          </div>
          <div className=" flex place-content-between  lg:mr-32 mt-5">
            <h2 className=" text-xs lg:text-xl mt-10 ml-6 lg:ml-10 font-medium lg:font-semibold">
              Latest Test Results
            </h2>
            <button
              onClick={() =>
                navigate(
                  "/zedi/operations/mtaas-talent/admin/test-dashboard/test_result"
                )
              }
              className="btn-outline-1 mt-9 px-2 text-xs  lg:px-6 text-center  lg:text-base lg:font-medium"
            >
              View all results
            </button>
          </div>

          <div className="mt-10 ml-5 lg:ml-10 grid grid-cols-3 bg-line pb-1">
            <div className="pt-2 w-3/12 ml-4 lg:ml-20">
              <h1 className="texts text-xs font-bold lg:text-base">NAME</h1>
            </div>

            <div className="pt-2 w-6/12 ml-6 lg:ml-0 ">
              <h1 className="texts text-xs lg:text-base">TEST </h1>
            </div>

            <div className="pt-2 w-3/12  ml-5 lg:ml-0">
              <h1 className="texts text-xs lg:text-base">RESULT</h1>
            </div>
          </div>

          {talentData?.map((talents) => (
            <div className="mt-4 ml-5 lg:ml-10 grid grid-cols-3  ">
              <div className=" w-3/12 ml-4 lg:ml-20 mb-4 pt-6 ">
                <h1 className=" text-sm lg:text-xl font-medium">
                  {talents.talent.firstName}
                </h1>
                <p className="invisible lg:visible">{talents.talent.email}</p>
              </div>

              <div className=" w-6/12 ml-6 lg:ml-0  pt-8">
                <h1 className="text-sm lg:text-base">{talents.stage}</h1>
              </div>

              <div
                className={`${
                  talents.status === "Failed"
                    ? "test-failed-1 lg:test-failed"
                    : talents.status === "Pending" || talents.status === null
                    ? "test-pending-1 lg:test-pending"
                    : "test-passed-1 lg:test-passed"
                }   w-3/12 ml-5 lg:ml-0 text-xs mt-6 pt-1`}
              >
                <h1 className="text-center pt-1">{talents.status}</h1>
              </div>
            </div>
          ))}
        </div>
      </Layout>
    </div>
  );
};

export default Test_dashboard;
