import React, { useState } from "react";
import { FiChevronDown, FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Logout = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  let fullname = localStorage.getItem("firstName");

  const logoutHandler = () => {
    localStorage.removeItem("adminToken");
    delete axios.defaults.headers.common["Authorization"];
    navigate("/zedi/operations/mtaas-talent/admin/login");
  };
  return (
    <div className="mt-9" >
      {open ?(
     <div className="out"  onClick={() => setOpen(!open)}>
      <h1  className="flex ">
      {fullname}
      <FiChevronDown
         
        onClick={() => setOpen(!open)}
        className="subtext self-center mr-1 font-poppins"
        size={16}
      />
      </h1>
    

          <li
            onClick={logoutHandler}
            className="subtext text-base absolute ml-10 flex mt-2 place-content-center logout pt-3"
          >
            <FiLogOut onClick={logoutHandler} className="mr-2 mt-1" />
            Logout
          </li>
        </div>
      ) : (
        <div className="out" onClick={() => setOpen(!open)}>
          <p
            
            className="flex"
          >
            {fullname}
            <FiChevronDown
             
              onClick={() => setOpen(!open)}
              className="subtext self-center mr-1 font-poppins"
              size={16}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default Logout;
