import React, { useState, useEffect } from "react";
import signed from "../../assets/images/talent-signed.svg";
import progress from "../../assets/images/test-progress.svg";
import taken from "../../assets/images/check-mark.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Admin_layout from '../../components/layout'


const Dashboard = () => {

  const [data, setData] = useState([]);
  const [talentData, setTalentData] = useState([]);
  const navigate = useNavigate();
  const [token, setToken] = useState(()=>localStorage.adminToken)

  const headers = {
    Authorization: "Bearer " + token
  };


  useEffect(() => {


    if (!token) {
      navigate("/zedi/operations/mtaas-talent/admin/login")
    }

  //eslint-disable-next-line
}, [token]);

useEffect(() => {

   const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/applications/overview`;
      await axios.get(api, { headers }).then((res) => {
        const data = res.data.data;
        setData(data);
      })
      .catch(function (error) {
        navigate("/zedi/operations/mtaas-talent/admin/login");
      });
    };
    getData()
  }, [token]);

useEffect(() => {
 const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/results?skip=0&limit=10`;
        await axios.get(api, { headers })
        .then((res) => {

        const dataInfo = res.data.data.docs;
        setTalentData(dataInfo);
      })
      .catch(function (error) {
        navigate("/zedi/operations/mtaas-talent/admin/login");
      });
    };
    getData()
//eslint-disable-next-line
  }, [token]);



  return (
    <div className="  allAuth none font-poppins">
      <Admin_layout>

          <div className=" light  mt-2 bg-base">
            <h1 className="text-2xl pl-5 lg:pl-10 pt-5 font-semibold texts">
              Dashboard
            </h1>
            <div className="gap-8 ml-5 lg:ml-10 lg:grid grid-flow-col  lg:mr-32">
              <div className="max-w-lg p-6 bg-white border light mt-10 rounded-lg ">
                <div className="flex mt-2">
                  <img className="pl-4  " src={signed} alt="" />
                  <span className="pl-2 pt-2 text-base inline-flex subtext">
                    Talent signed
                  </span>
                </div>

                <div className="pl-6 mt-5 ">
                  <h1 className="text-2xl font-semibold">
                    {" "}
                    {data.totalTalents}
                  </h1>
                </div>
                <hr className="mt-2 " />

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/talent-dashboard/registered_talents"
                    )
                  }
                  className="blue mt-5 ml-6 text-sm font-medium"
                >
                  View talents
                </button>
              </div>
              <div className="max-w-lg p-6 bg-white border light mt-10 rounded-lg ">
                <div className="flex mt-2">
                  <img className="pl-4  " src={progress} alt="" />
                  <span className="pl-2 pt-2 text-base inline-flex subtext">
                    Test in progress
                  </span>
                </div>

                <div className="pl-6 mt-5 ">
                  <h1 className="text-2xl font-semibold">
                    {data.totalTalentsInProgress}
                  </h1>
                </div>

                <hr className="mt-2 " />

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/test_result"
                    )
                  }
                  className="blue mt-5 ml-6 text-sm font-medium "
                >
                  View tests
                </button>
              </div>

              <div className="max-w-lg p-6 bg-white border light mt-10 rounded-lg ">
                <div className="flex mt-2">
                  <img className="pl-4  " src={taken} alt="" />
                  <span className="pl-2 pt-2 text-base inline-flex subtext">
                    Completed tests
                  </span>
                </div>

                <div className="pl-6 mt-5 ">
                  <h1 className="text-2xl font-semibold">
                    {data.totalAcceptedTalents}
                  </h1>
                </div>

                <hr className="mt-2 " />

                <button
                  onClick={() =>
                    navigate(
                      "/zedi/operations/mtaas-talent/admin/test-dashboard/completed_test_results"
                    )
                  }
                  className="blue mt-5 ml-6 text-sm font-medium "
                >
                  View tests
                </button>
              </div>
            </div>
            <div className=" flex place-content-between  lg:mr-32 mt-5">
              <h2 className=" text-xs lg:text-xl mt-10 ml-6 lg:ml-10 font-medium lg:font-semibold">
                Latest Test Results
              </h2>
              <button
                onClick={() =>
                  navigate(
                    "/zedi/operations/mtaas-talent/admin/test-dashboard/test_result"
                  )
                }
                className="btn-outline-1 mt-9 px-2 text-xs  lg:px-6 text-center  lg:text-base lg:font-medium"
              >
                View all results
              </button>
            </div>

            <div className="mt-10 ml-5 lg:ml-10 grid grid-cols-3 bg-line pb-1">
             <div className="pt-2 w-3/12 ml-4 lg:ml-20">
                <h1 className="texts text-xs font-bold lg:text-base">NAME</h1>
              </div>

              <div className="pt-2 w-6/12 ml-6 lg:ml-0 ">
                <h1 className="texts text-xs lg:text-base">TEST </h1>
              </div>

              <div className="pt-2 w-3/12  ml-5 lg:ml-0">
                <h1 className="texts text-xs lg:text-base">RESULT</h1>
              </div>
             </div>

            {talentData?.map((talents) => (
              <div className="mt-4 ml-5 lg:ml-10 grid grid-cols-3  ">
                <div className=" w-3/12 ml-4 lg:ml-20 mb-4 pt-6 ">
                  <h1 className=" text-sm lg:text-xl font-medium">
                    {talents.talent.firstName}
                  </h1>
                  <p className="invisible lg:visible">{talents.talent.email}</p>
                </div>

                <div className=" w-6/12 ml-6 lg:ml-0  pt-8">
                  <h1 className="text-sm lg:text-base">{talents.stage}</h1>
                </div>

                <div
                  className={`${
                    talents.status === "Failed"
                      ? "test-failed-1 lg:test-failed"
                      : talents.status === "Pending" || talents.status === null
                      ? "test-pending-1 lg:test-pending"
                      : "test-passed-1 lg:test-passed"
                  }   w-3/12 ml-5 lg:ml-0 text-xs mt-6 pt-`}
                >
                  <h1 className="text-center pt-1">{talents.status}</h1>
                </div>
              </div>
            ))}
          </div>
      </Admin_layout>
    </div>
  );
};

export default Dashboard;
