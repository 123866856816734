import React, { useEffect } from "react";
import Layout from "../components/user_layout";
import confirmation from "../assets/icons/confirmation.svg";

const Auth_confirmation = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      window.gtag("config", "AW-10859188675");
      window.gtag("event", "conversion", {
        send_to: "AW-10859188675/-3YsCI7fsJ0YEMOjiLoo",
      });
    }
  });

  return (
    <div className="confirmation font-poppins">
      <Layout>
        <div className="mx-auto text-center w-screen content-center px-8 pt-14 md:px-0 md:w-9/12">
          <img src={confirmation} alt="" className="mx-auto w-3/12" />
          <h1 className="font-bold text-[32px] pt-6 pb-3">
            Thank you for signing up!
          </h1>
          <p className="subtext text-[16px]">
            You have taken the first step towards becoming a part of the top 1%
            of the top 1%. You just need to confirm your email so we can get
            back to your application. <br /> <br />A confirmation email has been
            sent to your email address.
          </p>
        </div>
      </Layout>
    </div>
  );
};

export default Auth_confirmation;
