import React, { useState, useEffect } from "react";
// user_layout is the layout for endusers while layout is for admin
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import Mic from "../assets/images/mic.svg";
import MicOff from "../assets/images/mic-off.svg";
import ChevronRight from "../assets/images/chevron-right.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CURRENT_TEST_TOKEN } from "../utils/constants";
// import { object } from "yup";

// const initialState = {
//   submission: [],
// };
// const testToken =window.onload(localStorage.setItem("testToken", token))

// export const headers = {
//   Authorization: "Bearer " + localStorage.getItem("userToken"),
// };

const Speech_test = () => {
  const [questions, setQuestions] = useState([]);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const navigate = useNavigate();
  // const [retakeToken, setRetakeToken] = useState(() => localStorage.retakeToken);
  // const retakeTestToken = retakeToken
  const [userTranscript, setUserTranscript] = useState("");
  const [userSentence, setUserSentence] = useState(0);
  const [submission, setSubmission] = useState([]);
  const [index, setIndex] = useState(1);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const url = window.location.origin;
  const [token, setToken] = useState(() => localStorage.userToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const startRecordingHandler = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsRecording(true);
    setIsRecorded(false);
  };

  const stopRecordingHandler = () => {
    SpeechRecognition.stopListening();
    setIsRecording(false);
    setIsRecorded(true);
    const score = similarity(
      questions[userSentence]?.sentence
        .toLowerCase()
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s+/g, " "),
      userTranscript
        .toLowerCase()
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s+/g, " ")
    );
    const submissionResult = {
      sentenceId: questions[userSentence]?.id,
      score: Math.ceil(score * 100),
    };

    submission.push(submissionResult);
    setSubmission(submission);
  };

  const similarity = (s1, s2) => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    let longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  };

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let costs = [];
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  const nextQuestionHandler = () => {

    resetTranscript();
    setUserTranscript("");
    setUserSentence(userSentence + 1);
    setIndex(index + 1);
    setIsRecording(false);
    setIsRecorded(false);
  };

  useEffect(() => {
    const lastWord = transcript.split(" ");
    const currentWord =
      userTranscript > 0
        ? userTranscript + " " + lastWord[lastWord.length - 1]
        : transcript;
    setUserTranscript(currentWord);
    //eslint-disable-next-line
  }, [transcript]);

  useEffect(() => {
    const getData = async () => {
      const api = `${process.env.REACT_APP_API_URL}/v1/tests/questions/speech/talent?token=${localStorage.getItem(CURRENT_TEST_TOKEN)}`;
      await axios
        .get(api, { headers })
        .then((result) => {
          const questions = result.data.data.docs;

          setQuestions(questions);
        })
        .catch(function (error) {
          navigate("/login");
        });
    };
    getData();
    //eslint-disable-next-line
  }, []);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await axios.put(
      `${process.env.REACT_APP_API_URL}/v1/tests/speech-submission`,
      {
        token: localStorage.getItem(CURRENT_TEST_TOKEN),
        submission: submission,
        writtenTestUrl: `${url}/written_test_guidelines`,
        verbalTestUrl: `${url}/speech_test_guidelines`,
      },
      { headers }
    );

    navigate("/submit_voice_test");
  };

  return (
    <div className="hiddenPages font-poppins">
      <Layout>
        <div className="test">
          <Banner title="Speech Test" />
          <div>
            <div className="flex place-content-center text-xl my-10 font-medium texts">
              {questions.length === userSentence ? (
                <h1 className="mb-20"> Sentence 0 of 0 </h1>
              ) : (
                <h1> Sentence {index} of 5 </h1>
              )}
            </div>

            <div className="mx-auto w-8/12 mb-20  text-xl ">
              {questions[userSentence]?.sentence}
            </div>

            {isRecording && !isRecorded && (
              <button
                onClick={stopRecordingHandler}
                className="btn-stop flex  place-content-center text-base ml-72 pt-20 my-8 "
              >
                <img className="mr-1" src={MicOff} alt="mic" />
                <span className="">Stop recording</span>
              </button>
            )}

            {!isRecording && !isRecorded && (
              <button
                onClick={startRecordingHandler}
                className="btn flex place-content-center text-base ml-72 my-8 "
              >
                <img className="mr-1" src={Mic} alt="mic" />{" "}
                <span className=" ml-1">Start recording</span>
              </button>
            )}

            {isRecorded && (
              <button

                className="btn-recorded  flex  place-content-center text-base ml-72 pt-20 my-8 "
              >
                <img className="mr-1" src={Mic} alt="mic" /> Answer recorded
              </button>
            )}

            <div className="mt-40 flex place-content-end">
              {questions.length === userSentence + 1 ? (
                <button
                  onClick={handleSubmit}
                  className="btn text-[14px] pl-3 text-center flex justify-center mr-20  w-1/12"
                >
                  <span className="pl-3">Submit</span>{" "}
                  <img className="ml-2" src={ChevronRight} alt="mic" />
                </button>
              ) : isRecording && !isRecorded ?(
                <button
                  className="btn-dis text-[14px] pl-3 text-center flex justify-center mr-20  w-1/12"
                >
                  <span className="pl-3">Next</span>{" "}
                  <img className="ml-2" src={ChevronRight} alt="mic" />
                </button>
              )



              : (
                <button
                  onClick={nextQuestionHandler}
                  className="btn text-[14px] pl-3 text-center flex justify-center mr-20  w-1/12"
                >
                  <span className="pl-3">Next</span>{" "}
                  <img className="ml-2" src={ChevronRight} alt="mic" />
                </button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Speech_test;
