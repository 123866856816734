import React from "react";
import Layout from "../../components/user_layout";
import admin from "../../assets/images/admin.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Admin_signup = () => {
  const schema = yup.object().shape({
    firstName: yup.string().min(2).required(),
    lastName: yup.string().min(2).required(),
    email: yup.string().email("Not a valid email").required("Required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const adminSignupURL = `${process.env.REACT_APP_API_URL}/v1/admins`;

  const onSubmitHandler = (data) => {
    axios
      .post(adminSignupURL, data)
      .then(function (response) {
        navigate("/zedi/operations/mtaas-talent/admin/login");
      })
      .catch(function (error) {});
  };

  return (
    <div className="allAuth font-poppins">
      <Layout>
        <div className="mx-auto pt-20 pb-4 font-poppins grid place-items-center">
          <div className="'mx-auto bg-center">
            <img src={admin} alt="" className="object-center" />
          </div>
          <h1 className="text-2xl lg:text-[32px] header font-semibold mt-8">
            {" "}
            Admin sign up
          </h1>
        </div>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <div className=" mx-auto mb-8 w-4/12">
            <label htmlFor="">First name</label> <br />
            <input
              type="text"
              name="firstName"
              required
              className="form-control w-full mt-2 font-poppins"
              placeholder="First name"
              {...register("firstName", { required: true })}
            />
            <p className="text-red-600 font-bold">
              {errors.firstName && "First Name required"}
            </p>
          </div>
          <div className=" mx-auto mb-8 w-4/12">
            <label htmlFor="">Last name</label> <br />
            <input
              type="text"
              name="lastName"
              required
              className="form-control w-full mt-2 font-poppins"
              placeholder="Last name"
              {...register("lastName", { required: true })}
            />
            <p className="text-red-600 font-bold">
              {errors.lastName && "Last Name required"}
            </p>
          </div>
          <div className=" mx-auto mb-8 w-4/12">
            <label htmlFor="">Email</label> <br />
            <input
              type="email"
              name="email"
              required
              className="form-control w-full mt-2 font-poppins"
              placeholder="e.g 0123name@email.com45"
              {...register("email", { required: true })}
            />
            <p className="text-red-600 font-bold">
              {errors.email && "please insert your valid email"}
            </p>
          </div>

          <div className="mx-auto w-4/12">
            <button className="btn mt-10 font-medium font-poppins form-control w-full text-[14px] flex mx-auto place-content-center">
              Logging In
            </button>
          </div>
        </form>
      </Layout>
    </div>
  );
};

export default Admin_signup;
