import React, { useState, useEffect } from "react";
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import { HiArrowRight } from "react-icons/hi";
import { useSearchParams, useNavigate } from "react-router-dom";
import { CURRENT_TEST_ROUTE, CURRENT_TEST_TOKEN } from "../utils/constants";

const Role_specific_test_guidelines = () => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(() => localStorage.userToken);
  localStorage.setItem(CURRENT_TEST_ROUTE, window.location.pathname)
  localStorage.setItem(CURRENT_TEST_TOKEN, searchParams.get("token"))

  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem(
      "roleSpecificGuideLines",
      "/role_specific_test_guidelines"
    );

    if (!token) {
      navigate("/login");
    }

    //eslint-disable-next-line
  }, [token]);

  return (
    <div className="hiddenPages">
      <Layout>
        <div className="test_guidelines font-poppins">
          <Banner title="Role Specific Test" />
          <div className="px-8 lg:px-32 mx-20 pb-28 pt-16  bg-[#F5F3FB] my-10">
            <h1 className=" text-[20px] font-semibold base">
              General Instructions
            </h1>
            <p className="texts leading-10  text-[18px] pt-4 mb-6">
              We encourage you to manage your time and answer all the questions.
            </p>
            <p className="texts leading-10  text-[18px] mb-6">
              This is a role specific test.
            </p>
            <p className="texts leading-10  text-[18px]">
              Please take the time to read the instructions carefully before you
              begin the test. We recommend you have the following ready:
            </p>
            <ul className="list texts leading-10 text-[18px] mx-auto w-11/12">
              <li>Google chrome (to access the test)</li>
              <li>Access to a stable internet </li>
              <li>Fully charged computer</li>
            </ul>
            <p className="texts leading-10 text-[18px] mt-10">
              NB: You are not to open any other windows or refresh the page. If
              you do, the assessment will end immediately. </p>
            <p className="texts leading-10 text-[18px] mt-2">
               When you are ready,
              agree to the rules by clicking the check box below and then hit
              the<span className="font-bold"> Start Test</span> button.
            </p>
          </div>
          <form className="grid lg:grid-cols-2 mx-20" onSubmit={() => navigate('/role_specific_test')}>
            <div className="flex pb-6 lg:pb-0">
              <input type="checkbox" name="" id="" required/>
              <p
                className="pl-2 self-center text-[18px]
                "
              >
                I agree to all the Rules and Regulations{" "}
              </p>
            </div>
            <div>
                <button className="btn float-right w-full lg:w-96 flex justify-center" type="submit" >
                  <span className="pt-1 self-center">Start Test</span>
                  <HiArrowRight className="self-center ml-2" />
                </button>
            </div>
          </form>
        </div>
      </Layout>
    </div>
  );
};

export default Role_specific_test_guidelines;
