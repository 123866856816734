import React, { useState, useEffect } from "react";
// user_layout is the layout for endusers while layout is for admin
import Layout from "../components/user_layout";
import Banner from "../components/banner";
import Mic from "../assets/images/mic.svg";
import MicOff from "../assets/images/mic-off.svg";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Test_mic = () => {
  const { transcript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();
  const navigate = useNavigate();
  const [_testToken, _setTestToken] = useState(() => localStorage.testToken);
  const testToken = _testToken;
  const [userTranscript, setUserTranscript] = useState("");
  const [submission, setSubmission] = useState([]);
  const [isRecording, setIsRecording] = useState(false);
  const [isRecorded, setIsRecorded] = useState(false);
  const [scores, setScores] = useState(0);
  const url = window.location.origin;
  const question =
    "What steps should a good content manager take in deciding a marketing strategy for a brand or organization? ";

  if (!localStorage.justOnce) {
    localStorage.setItem("justOnce", "true");
    window.location.reload();
  }

  // On page reload pop up message
  window.onbeforeunload = function () {
    return "Reload the page to retest the mic !";
  };
  const [token, setToken] = useState(() => localStorage.userToken);

  const headers = {
    Authorization: "Bearer " + token,
  };

  const startRecordingHandler = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsRecording(true);
    setIsRecorded(false);
  };

  const stopRecordingHandler = () => {
    SpeechRecognition.stopListening();
    setIsRecording(false);
    setIsRecorded(true);
    const score = similarity(
      question
        .toLowerCase()
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s+/g, " "),
      userTranscript
        .toLowerCase()
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s+/g, " ")
    );

    setScores(Math.ceil(score * 100));
  };

  const similarity = (s1, s2) => {
    let longer = s1;
    let shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    let longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (
      (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength)
    );
  };

  function editDistance(s1, s2) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    let costs = [];
    for (let i = 0; i <= s1.length; i++) {
      let lastValue = i;
      for (let j = 0; j <= s2.length; j++) {
        if (i === 0) costs[j] = j;
        else {
          if (j > 0) {
            let newValue = costs[j - 1];
            if (s1.charAt(i - 1) !== s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue), costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0) costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  useEffect(() => {
    const lastWord = transcript.split(" ");
    const currentWord =
      userTranscript > 0
        ? userTranscript + " " + lastWord[lastWord.length - 1]
        : transcript;
    setUserTranscript(currentWord);
    //eslint-disable-next-line
  }, [transcript]);

  if (!browserSupportsSpeechRecognition) {
    return <span>Browser doesn't support speech recognition.</span>;
  }

  return (
    <div className="hiddenPages font-poppins">
      <Layout>
        <div className="test_guidelines mb-96">
          <Banner title="Speech Test" />
          <div>
            <div className="mx-auto text-xl my-10 font-medium texts">
              <h1 className="text-center "> Mic check </h1>
            </div>

            <div className="mx-auto w-8/12 mb-20  text-xl ">{question}</div>

            {isRecording && !isRecorded && (
              <button
                onClick={stopRecordingHandler}
                className="btn-stop flex  place-content-center text-base ml-72 pt-20 my-8 "
              >
                <img className="mr-1" src={MicOff} alt="mic" />
                <span className="">Stop recording</span>
              </button>
            )}

            {!isRecording && !isRecorded && (
              <button
                onClick={startRecordingHandler}
                className="btn flex place-content-center text-base ml-72 my-8 "
              >
                <img className="mr-1" src={Mic} alt="mic" />{" "}
                <span className=" ml-1">Start recording</span>
              </button>
            )}

            {isRecorded && scores < 80 && (
              <button className="btn-red flex  place-content-center text-base ml-72 pt-20 my-8 ">
                <img className="mr-1" src={Mic} alt="mic" />
                Recording not good
              </button>
            )}

            {isRecorded && scores >= 80 && (
              <button className="btn-recorded  flex  place-content-center text-base ml-72 pt-20 my-8 ">
                <img className="mr-1" src={Mic} alt="mic" />
                Mic check done
              </button>
            )}

            <div className="mt-40 flex place-content-end">
              {isRecorded && scores < 80 && (
                <button
                  onClick={() => (window.location.href = "/test_mic")}
                  className="btn text-[14px] pl-3 text-center flex justify-center mr-20  w-3/12"
                >
                  <span className="pl-3">Restart mic check</span>{" "}
                </button>
              )}
              {isRecorded && scores >= 80 && (
                <button
                  onClick={() => navigate("/tests/speech-test")}
                  className="btn text-[14px] pl-3 text-center flex justify-center mr-20  w-3/12"
                >
                  <span className="pl-3">Start test</span>{" "}
                </button>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};
export default Test_mic;
